/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { observable, action, runInAction, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Segment, Icon, Button, Header, Divider, Message, Progress } from 'semantic-ui-react';

import { parseError } from '../../../helpers/utils';
//import Carryout from './parts/Carryout';
import Marketplace from '../../environments/marketplace';

// edit 2359 - add support for EMR notebook selection
class Ready extends React.Component {
  choice = '';
  error = undefined;
  loading = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      handleChoice: action,
      choice: observable,
      error: observable,
      loading: observable,
    });
  }

  handleChoice = value => {
    this.error = undefined;
    this.loading = true;
    this.choice = value;
    const carryoutStore = this.props.carryoutStore;

    carryoutStore
      .load()
      .then(() => {
        runInAction(() => {
          this.props.searchWizard.setCurrentStep(3);
          this.loading = false;
        });
      })
      .catch(err => {
        runInAction(() => {
          this.error = parseError(err);
          this.loading = false;
        });
      });
  };

  render() {
    const is = str => str === this.choice;
    const isError = !_.isNil(this.error);
    const disabled = !is('') && !isError;

    return (
      <div className="mt3 animated fadeIn">
        <Segment className="pr3 pl3 pb3 pt0">
          <div className="flex">
            <div className="pt3 mr3 flex-auto">{this.renderYouAreReady()}</div>
            {!this.props.searchWizard.info.partial &&
              !this.props.searchWizard.info.hasSecureStudies &&
              !this.props.searchWizard.info.emrConflict && (
                <div className="pt2 mr2">
                  <Header as="h2" textAlign="center" className="mt0" color="grey">
                    Choose One
                  </Header>
                  <Button.Group size="large" className="width-870-px">
                    <Button disabled={disabled} onClick={() => this.handleChoice('doItForMe')}>
                      Set up a compute resource for me
                    </Button>
                    {/* Remove download key function
                <Button.Or />             
                <Button disabled={disabled || this.props.searchWizard.info.hasApiStudies} onClick={() => this.handleChoice('carryout')}>
                  Generate download key
                </Button>
				*/}
                  </Button.Group>
                </div>
              )}
            {/*
             * For selection with secure studies, users should only be able to create SECURE workspaces.
             * For secure workspaces, API studies won't work, so need to make sure that no API studies were selected together
             * No other options should be allowed.
             */
            !this.props.searchWizard.info.partial &&
              this.props.searchWizard.info.hasSecureStudies &&
              !this.props.searchWizard.info.conflict && (
                <div className="pt2 mr2">
                  <Button.Group size="large" className="width-870-px">
                    <Button disabled={disabled} onClick={() => this.handleChoice('doItForMeSecure')}>
                      Set up a SECURE compute resource for me
                    </Button>
                  </Button.Group>
                </div>
              )}
          </div>
          {(is('carryout') || is('doItForMe') || is('doItForMeSecure')) && this.renderChoiceSection()}
        </Segment>
      </div>
    );
  }

  // edit 2359 - add support for EMR notebook selection and initial support for data collections
  // edit chenjqp - add support for secure emr-hail
  renderChoiceSection() {
    const { _carryoutStore, filesSelection, notebooksSelection } = this.props;
    const is = str => str === this.choice;
    const choice = is('carryout')
      ? 'Generate download key'
      : is('doItForMe')
      ? 'Set up a compute resource for me'
      : 'Set up a SECURE compute resource for me';
    const isBusy = this.loading;
    const isError = !_.isNil(this.error);
    const selection = notebooksSelection.notebookNames;

    return (
      <div className="animated fadeIn">
        <Divider />
        <Header as="h3" textAlign="left" className="mt0" color="grey">
          <b>You chose</b> ▸ &nbsp; {choice}
        </Header>
        {isError && this.renderError()}
        {!isError && isBusy && this.renderProgress()}
        {/*!isError && !isBusy && is('carryout') && <Carryout />*/}
        {!isError && !isBusy && is('doItForMe') && (
          <Marketplace
            notebooks={selection}
            files={filesSelection.getFiles()}
            onSuccess={() => {
              filesSelection.clear();
              notebooksSelection.clear();
            }}
          />
        )}
        {!isError && !isBusy && is('doItForMeSecure') && (
          <Marketplace
            notebooks={selection}
            files={filesSelection.getFiles()}
            secureFiles={_.map(_.filter(filesSelection.files.toJSON(), { isSecure: true }), item => item.id)}
            onSuccess={() => filesSelection.clear()}
            isSecure
          />
        )}
      </div>
    );
  }

  renderError() {
    const message = _.get(this.error, 'message', 'Hmm... something went wrong');

    return (
      <Message negative className="clearfix">
        <Message.Header>A problem was encountered</Message.Header>
        <p>{message}</p>
        <Button floated="right" basic color="red" onClick={() => this.handleChoice(this.choice)}>
          Retry
        </Button>
      </Message>
    );
  }

  renderProgress() {
    return (
      <Progress percent={100} active color="blue">
        <span className="color-grey">Processing</span>
      </Progress>
    );
  }

  renderYouAreReady() {
    const info = this.props.searchWizard.info;
    const notApprovedStudies = info.studies.notApproved;
    const notApproved = info.selection.notApproved;
    const approvedStudies = info.studies.approved;
    const approved = info.selection.approved;
    const isPartial = info.partial;
    const icon = isPartial ? 'announcement' : 'thumbs up';

    const partial = (
      <div>
        You don&apos;t have access to <b>{notApproved.nice}</b> {notApproved.word} from <b>{notApprovedStudies.nice}</b>{' '}
        {notApprovedStudies.word}
        <div>
          But you have access to <b>{approved.nice}</b> {approved.word} from <b>{approvedStudies.nice}</b>{' '}
          {approvedStudies.word}
        </div>
        <div>Please ensure you have access to all studies.</div>
      </div>
    );

    const all = (
      <div>
        You have access to all the runs that you need
        <div>
          <b>{approved.nice}</b> {approved.word}
        </div>
      </div>
    );

    const isConflict = info.conflict;
    const conflict = (
      <div>
        Secure studies <b>cannot</b> be selected with API studies, please reselect your studies!
      </div>
    );

    const isEmrConflict = info.emrConflict;
    const emrConflict = (
      <div>
        Notebooks <b>cannot</b> be selected without Secure studies, please reselect your studies!
      </div>
    );

    return (
      <Header as="h2" textAlign="left" className="" color="grey">
        <Icon name={icon} circular />
        <Header.Content>
          {isPartial && 'You have partial access'}
          {!isPartial && !isConflict && !isEmrConflict && 'You are ready'}
          {!isPartial && (isConflict || isEmrConflict) && 'You have conflicts'}
          <Header.Subheader>
            {isPartial && partial}
            {!isPartial && !isConflict && !isEmrConflict && all}
            {!isPartial && isConflict && conflict}
            {!isPartial && isEmrConflict && emrConflict}
          </Header.Subheader>
        </Header.Content>
      </Header>
    );
  }
}

export default inject('searchWizard', 'notebooksSelection', 'filesSelection', 'carryoutStore')(observer(Ready));
