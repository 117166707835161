/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http:aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { BaseStore } from '../BaseStore';
import { EnvironmentConfiguration } from './EnvironmentConfiguration';
import { CustomInstanceType } from './CustomInstanceType';
import { SagemakerInstanceType } from './SagemakerInstanceType';
import { consolidateToMap } from '../../helpers/utils';
import {
  getCustomEnvironmentConfigurations,
  getEnvironmentSpotPriceHistory,
  getEnvironmentOnDemandPrice,
  getSagemakerEnvironmentConfigurations,	
} from '../../helpers/api';

// edited by 2359 - added support for custom environment
const EnvironmentConfigurationsStore = BaseStore.named('EnvironmentConfigurationsStore')
  .props({
    customs: types.map(CustomInstanceType),
    configurations: types.map(EnvironmentConfiguration),
    smtypes: types.map(SagemakerInstanceType),	  
    heartbeatInterval: -1,
  })
  .actions(self => {
    return {
      async doLoad() {
        const environmentConfigurations = await getEnvironmentConfigurations();
        const InstanceTypes = await getCustomEnvironmentConfigurations();
        const SagemakerInstanceTypes = await getSagemakerEnvironmentConfigurations();

        self.runInAction(() => {
          consolidateToMap(self.configurations, environmentConfigurations, (exiting, newItem) => {
            exiting.setEnvironmentConfiguration(newItem);
          });

          consolidateToMap(self.customs, InstanceTypes, (exiting, newItem) => {
            exiting.setCustomInstanceType(newItem);
          });

	  consolidateToMap(self.smtypes, SagemakerInstanceTypes, (exiting, newItem) => {
            exiting.setSagemakerInstanceType(newItem);
          });
	
        });
      },

      async getCustomEnvironments() {
        const allTypes = await getCustomEnvironmentConfigurations();
	// only show x86_64 compatible instance types      
	const InstanceTypes = _.filter(allTypes, item => _.has(item, 'ProcessorInfo') && _.has(item.ProcessorInfo, 'SupportedArchitectures') && _.includes(item.ProcessorInfo.SupportedArchitectures, 'x86_64'));      
        return InstanceTypes;
      },

      async getSagemakerEnvironments() {
        const InstanceTypes = await getSagemakerEnvironmentConfigurations();
        return InstanceTypes;
      },	    

      async getCustomSpotBidPrice(type) {
        const priceHistory = await getEnvironmentSpotPriceHistory(type);
        const avgPriceHistory = priceHistory.reduce(
          (result, { spotPrice }) => result + spotPrice / priceHistory.length,
          0,
        );
        return avgPriceHistory * 1.3;
      },

      async getCustomOnDemandPrice(type) {
        const pricingData = await getEnvironmentOnDemandPrice(type);
        const onDemand = pricingData?.PriceList[0]?.terms?.OnDemand;
        const priceDimensions = _.find(onDemand, (_item, _index) => true).priceDimensions;
        const pricePerUnit = _.find(priceDimensions, (_item, _index) => true)?.pricePerUnit?.USD;
        return pricePerUnit;
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.configurations.size === 0;
    },

    get total() {
      return self.configurations.size;
    },

    get list() {
      const result = [];
      self.configurations.forEach(configuration => result.push(configuration));

      return _.sortBy(result, ['id']);
    },

    get customlist() {
      const result = [];
      _.forEach(self.customs, configuration => result.push(configuration));

      return result;
    },

    get sagemakerlist() {
      const result = [];
      _.forEach(self.smtypes, configuration => result.push(configuration));

      return result;
    },
	    

    getConfiguration(id) {
      return self.configurations.get(id);
    },
  }));

// edit 2359 - add support for parallel cluster
// edit 2359 - add support for EMR-hail
// edit chenjqp - add support for secure emr-hail
async function getEnvironmentConfigurations() {
  let idCounter = 1;
  return [
    {
      type: 'sagemaker',
      size: 'ml.t2.medium',
      label: 'Small',
      price: 0.0464,
      defaultCidr: '0.0.0.0/0',
      description:
        'A small research workspace meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '4',
        },
        {
          key: 'Memory (GiB)',
          value: '16',
        },
      ],
    },
    {
      type: 'sagemaker',
      size: 'ml.m5.4xlarge',
      label: 'Medium',
      price: 1.075,
      defaultCidr: '0.0.0.0/0',
      description: 'A medium research workspace meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '32',
        },
        {
          key: 'Memory (GiB)',
          value: '128',
        },
      ],
    },
    {
      label: 'Large',
      type: 'sagemaker',
      size: 'ml.m5.24xlarge',
      price: 6.451,
      defaultCidr: '0.0.0.0/0',
      description: 'A large research workspace meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '96',
        },
        {
          key: 'Memory (GiB)',
          value: '384',
        },
      ],
    },
    {
      type: 'emr-hail',
      size: 'r5.xlarge',
      label: 'Small',
      price: 0.304 + 0.063 + (0.304 + 0.063) + (0.0252 + 0.008), // price of r5.xlarge master + r5.xlarge core/task + ALB
      defaultCidr: '',
      description:
        'A small research workspace meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour. Contains 1 master of 4 vCPUs and 1 core of 4 vCPUs on-demand.',
      emrConfiguration: {
        workerInstanceSize: 'r5',
        workerInstanceCount: 0,
        workerInstanceOnDemandPrice: 0.192,
        diskSizeGb: 64,
      },
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      type: 'emr-hail',
      size: 'r5.xlarge',
      label: 'Medium',
      price: 0.304 + 0.063 + (0.304 + 0.063) + (0.0252 + 0.008), // price of r5.xlarge master + r5.xlarge core/task + ALB
      emrConfiguration: {
        workerInstanceSize: 'r5',
        workerInstanceCount: 16,
        workerInstanceOnDemandPrice: ((0.304 + 0.063) * 4) / 16,
        diskSizeGb: 64,
      },
      defaultCidr: '',
      description:
        'A medium research workspace meant for average sized problems. Contains 1 master of 4 vCPUs, 1 core of 4 vCPUs on-demand and 16 vCPU from spot instances. Spot pricing for additional worker nodes are set at a maximum price of 45% of the on demand price.',
      properties: [
        {
          key: 'vCPU',
          value: '24',
        },
        {
          key: 'Memory (GiB)',
          value: '192',
        },
      ],
    },
    {
      label: 'Large',
      type: 'emr-hail',
      size: 'r5.xlarge',
      price: 0.304 + 0.063 + (0.304 + 0.063) + (0.0252 + 0.008), // price of r5.xlarge master + r5.xlarge core/task + ALB
      emrConfiguration: {
        workerInstanceSize: 'r5',
        workerInstanceCount: 64,
        workerInstanceOnDemandPrice: ((0.304 + 0.063) * 16) / 64,
        diskSizeGb: 64,
      },
      defaultCidr: '',
      description:
        'A large research workspace meant for the largest of problems.  Contains 1 master of 4 vCPUs, 1 core of 4 vCPUs on-demand and 64 vCPU from spot instances. Spot pricing for additional worker nodes are set at a maximum price of 45% of the on demand price.',
      properties: [
        {
          key: 'vCPU',
          value: '72',
        },
        {
          key: 'Memory (GiB)',
          value: '576',
        },
      ],
    },
    /*{
      size: 'r5.2xlarge',
      type: 'ec2-linux',
      label: 'Small',
      defaultCidr: '',
      price: 0.504,
      description:
        'A small environment is meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      size: 'r5.8xlarge',
      type: 'ec2-linux',
      label: 'Medium',
      defaultCidr: '',
      price: 2.016,
      description: 'A medium environment is meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '32',
        },
        {
          key: 'Memory (GiB)',
          value: '256',
        },
      ],
    },
    {
      label: 'Large',
      type: 'ec2-linux',
      size: 'r5.16xlarge',
      defaultCidr: '',
      price: 4.032,
      description: 'A large environment is meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '64',
        },
        {
          key: 'Memory (GiB)',
          value: '512',
        },
      ],
    },
    {
      size: 'r5.2xlarge',
      type: 'secure-ec2-linux',
      label: 'Small',
      defaultCidr: '',
      price: 0.504 + 0.0712,
      description:
        'A small environment is meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      size: 'r5.8xlarge',
      type: 'secure-ec2-linux',
      label: 'Medium',
      defaultCidr: '',
      price: 2.016 + 0.0712,
      description: 'A medium environment is meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '32',
        },
        {
          key: 'Memory (GiB)',
          value: '256',
        },
      ],
    },
    {
      label: 'Large',
      type: 'secure-ec2-linux',
      size: 'r5.16xlarge',
      defaultCidr: '',
      price: 4.032 + 0.0712,
      description: 'A large environment is meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '64',
        },
        {
          key: 'Memory (GiB)',
          value: '512',
        },
      ],
    },*/
    {
      size: 'r5.2xlarge',
      type: 'ec2-windows',
      label: 'Small',
      defaultCidr: '',
      price: 0.872,
      description:
        'A small environment is meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      size: 'r5.8xlarge',
      type: 'ec2-windows',
      label: 'Medium',
      defaultCidr: '',
      price: 3.488,
      description: 'A medium environment is meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '32',
        },
        {
          key: 'Memory (GiB)',
          value: '256',
        },
      ],
    },
    {
      label: 'Large',
      type: 'ec2-windows',
      size: 'r5.16xlarge',
      defaultCidr: '',
      price: 6.976,
      description: 'A large environment is meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '64',
        },
        {
          key: 'Memory (GiB)',
          value: '512',
        },
      ],
    },
    {
      size: 't3.small',
      type: 'secure-vetting',
      label: 'Small',
      defaultCidr: '',
      price: 0.0448,
      description: 'A small environment is meant for vetting the secure outputs. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '2',
        },
        {
          key: 'Memory (GiB)',
          value: '2',
        },
      ],
    },
    {
      size: 'r5.2xlarge',
      type: 'ec2-alinux2',
      label: 'Small',
      defaultCidr: '',
      price: 0.504,
      description:
        'A small environment is meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      size: 'r5.8xlarge',
      type: 'ec2-alinux2',
      label: 'Medium',
      defaultCidr: '',
      price: 2.016,
      description: 'A medium environment is meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '32',
        },
        {
          key: 'Memory (GiB)',
          value: '256',
        },
      ],
    },
    {
      label: 'Large',
      type: 'ec2-alinux2',
      size: 'r5.16xlarge',
      defaultCidr: '',
      price: 4.032,
      description: 'A large environment is meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '64',
        },
        {
          key: 'Memory (GiB)',
          value: '512',
        },
      ],
    },
    {
      size: 'r5.2xlarge',
      type: 'secure-ec2-alinux2',
      label: 'Small',
      defaultCidr: '',
      price: 0.504 + 0.0712,
      description:
        'A small environment is meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      size: 'r5.8xlarge',
      type: 'secure-ec2-alinux2',
      label: 'Medium',
      defaultCidr: '',
      price: 2.016 + 0.0712,
      description: 'A medium environment is meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '32',
        },
        {
          key: 'Memory (GiB)',
          value: '256',
        },
      ],
    },
    {
      label: 'Large',
      type: 'secure-ec2-alinux2',
      size: 'r5.16xlarge',
      defaultCidr: '',
      price: 4.032 + 0.0712,
      description: 'A large environment is meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '64',
        },
        {
          key: 'Memory (GiB)',
          value: '512',
        },
      ],
    },
    //    {
    //      type: 'parallel-cluster',
    //      size: 't2.micro',
    //      label: 'Small',
    //      defaultCidr: '',
    //      price: 0.504,
    //      description:
    //        'A small environment is meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
    //      properties: [
    //        {
    //          key: 'vCPU',
    //          value: '8',
    //        },
    //        {
    //          key: 'Memory (GiB)',
    //          value: '64',
    //        },
    //      ],
    //    },
    {
      size: 'r5.2xlarge',
      type: 'parallel-cluster',
      label: 'Small',
      defaultCidr: '',
      price: 0.504,
      description:
        'A small environment is meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      size: 'r5.8xlarge',
      type: 'parallel-cluster',
      label: 'Medium',
      defaultCidr: '',
      price: 2.016,
      description: 'A medium environment is meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '32',
        },
        {
          key: 'Memory (GiB)',
          value: '256',
        },
      ],
    },
    {
      label: 'Large',
      type: 'parallel-cluster',
      size: 'r5.16xlarge',
      defaultCidr: '',
      price: 4.032,
      description: 'A large environment is meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '64',
        },
        {
          key: 'Memory (GiB)',
          value: '512',
        },
      ],
    },
    {
      type: 'secure-emr-hail',
      size: 'r5.xlarge',
      label: 'Small',
      price: 0.304 + 0.063 + (0.304 + 0.063) + 0.0712, // price of r5.xlarge master + r5.xlarge core/task + t3.medium windows bastion
      defaultCidr: '',
      description:
        'A small research workspace meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour. Contains 1 master of 4 vCPUs and 1 core of 4 vCPUs on-demand.',
      emrConfiguration: {
        workerInstanceSize: 'r5',
        workerInstanceCount: 0,
        workerInstanceOnDemandPrice: 0.192,
        diskSizeGb: 64,
      },
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      type: 'secure-emr-hail',
      size: 'r5.xlarge',
      label: 'Medium',
      price: 0.304 + 0.063 + (0.304 + 0.063) + 0.0712, // price of r5.xlarge master + r5.xlarge core/task + t3.medium windows bastion
      emrConfiguration: {
        workerInstanceSize: 'r5',
        workerInstanceCount: 16,
        workerInstanceOnDemandPrice: ((0.304 + 0.063) * 4) / 16,
        diskSizeGb: 64,
      },
      defaultCidr: '',
      description:
        'A medium research workspace meant for average sized problems. Contains 1 master of 4 vCPUs, 1 core of 4 vCPUs on-demand and 16 vCPU from spot instances. Spot pricing for additional worker nodes are set at a maximum price of 45% of the on demand price.',
      properties: [
        {
          key: 'vCPU',
          value: '24',
        },
        {
          key: 'Memory (GiB)',
          value: '192',
        },
      ],
    },
    {
      label: 'Large',
      type: 'secure-emr-hail',
      size: 'r5.xlarge',
      price: 0.304 + 0.063 + (0.304 + 0.063) + 0.0712, // price of r5.xlarge master + r5.xlarge core/task + t3.medium windows bastion
      emrConfiguration: {
        workerInstanceSize: 'r5',
        workerInstanceCount: 64,
        workerInstanceOnDemandPrice: ((0.304 + 0.063) * 16) / 64,
        diskSizeGb: 64,
      },
      defaultCidr: '',
      description:
        'A large research workspace meant for the largest of problems.  Contains 1 master of 4 vCPUs, 1 core of 4 vCPUs on-demand and 64 vCPU from spot instances. Spot pricing for additional worker nodes are set at a maximum price of 45% of the on demand price.',
      properties: [
        {
          key: 'vCPU',
          value: '72',
        },
        {
          key: 'Memory (GiB)',
          value: '576',
        },
      ],
    },
    {
      size: 'r5.2xlarge',
      type: 'ami-parallel-cluster',
      label: 'Small',
      defaultCidr: '',
      price: 0.504,
      description:
        'A small environment is meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '8',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },
    {
      size: 'r5.8xlarge',
      type: 'ami-parallel-cluster',
      label: 'Medium',
      defaultCidr: '',
      price: 2.016,
      description: 'A medium environment is meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '32',
        },
        {
          key: 'Memory (GiB)',
          value: '256',
        },
      ],
    },
    {
      label: 'Large',
      type: 'ami-parallel-cluster',
      size: 'r5.16xlarge',
      defaultCidr: '',
      price: 4.032,
      description: 'A large environment is meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '64',
        },
        {
          key: 'Memory (GiB)',
          value: '512',
        },
      ],
    },
    {
      type: 'secure-sagemaker',
      size: 'ml.t3.medium',
      label: 'Small',
      price: 0.063,
      defaultCidr: '',
      description:
        'A small research workspace meant for prototyping and proving out scripts before scaling up to a larger. It costs the least amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '2',
        },
        {
          key: 'Memory (GiB)',
          value: '4',
        },
      ],
    },
    {
      type: 'secure-sagemaker',
      size: 'ml.m5.4xlarge',
      label: 'Medium',
      price: 1.075,
      defaultCidr: '',
      description: 'A medium research workspace meant for average sized problems.',
      properties: [
        {
          key: 'vCPU',
          value: '16',
        },
        {
          key: 'Memory (GiB)',
          value: '64',
        },
      ],
    },	 
    {
      label: 'Large',
      type: 'secure-sagemaker',
      size: 'ml.m5.16xlarge',
      price: 4.608,
      defaultCidr: '',
      description: 'A large research workspace meant for the largest of problems. It costs the most amount per hour.',
      properties: [
        {
          key: 'vCPU',
          value: '64',
        },
        {
          key: 'Memory (GiB)',
          value: '256',
        },
      ],
    }	  
  ].map(config => ({ ...config, id: `${idCounter++}` }));
}

function registerModels(globals) {
  globals.environmentConfigurationsStore = EnvironmentConfigurationsStore.create({}, globals);
}

export { EnvironmentConfigurationsStore, registerModels };
