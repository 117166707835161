
import { useEffect } from 'react';
import { Container, Icon, Label, Segment } from 'semantic-ui-react';
import { displayError } from '../../helpers/notification';
import EnvironmentTerminatePrompt from '../environments/EnvironmentTerminatePrompt';
import { Environment } from '../../models/environments/Environment';
import { stopEnvironment } from '../../helpers/api';

import useEnvCosts from './useEnvCosts';
import useEnvTotalCosts from './useEnvTotalCosts';

import './Dashboard.css';


const handleStopEnvironment = async(environment, uiEventBus) => {
   //event.preventDefault();
   //event.stopPropagation();
   try {
      await stopEnvironment(environment.id);
      environment.markAsStopping();	   
      await uiEventBus.fireEvent('environment', environment);
      window.location.reload(false);	   
   } catch (error) {
      displayError(error);
   }
}

function ButtonStop({env, uiEventBus}) {
  const { costs, ...otherProperties } = env;
  const environment = Environment.create({...otherProperties}); // remove costs field	
  return (
    <Label color="orange" className="cursor-pointer" data-id={env.id} onClick={() => handleStopEnvironment(environment, uiEventBus)}>
          <Icon name="stop" />
          Stop
    </Label>
  )
}

function ButtonTerminate({env, uiEventBus}) {
  const { costs, ...otherProperties } = env;
  const environment = Environment.create({...otherProperties}); // remove costs field	
  return (
    <div className="mb1 mt5 breakout" onClick={event => event.stopPropagation()}>
        <EnvironmentTerminatePrompt mode="dashboard" uiEventBus={uiEventBus} environment={environment} />
    </div>
  )	    
}

function Content({costs, totalCosts, uiEventBus}) {

  const getCostBreakdown = data => {
    const totalCostByService =  data.reduce((result, row) => {

      Object.entries(row.cost).forEach(([service, serviceCost]) => {
        let amount = (typeof serviceCost.amount === 'string') ? parseFloat(serviceCost.amount) : serviceCost.amount;
	result[service] = (result[service] || 0) + amount;
      });

      return result;
    }, {});

    return Object.entries(totalCostByService).map(
      ([name, cost]) => ({name, cost})
    )
  }

  const getStatus = status => {
    const statusMap = {
      'COMPLETED': 'Ready',
      'TERMINATED': null,
      'FAILED': 'Error',
      'STOPPING_FAILED': null,
      'STARTING_FAILED': null,
      'STOPPED': null,
      'STOPPING': null,
      'TERMINATING': null,
      'TERMINATING_FAILED': null,
    }
    return (statusMap[status] || status).toLowerCase();
  }

  const showStopButton = status => {
    return [
      'COMPLETED',
      'STOPPING_FAILED',
    ].includes(status);
  }	    

  const showTerminateButton = status => {
    return [
      'COMPLETED',
      'STOPPED',
      'STOPPING_FAILED',
      'STOPPING',
    ].includes(status);
  }

  return (
    <table className="dashboard--table">

      <thead>
        <tr className="dashboard--row">
          <th className="left-align">Environment</th>
          <th>Status</th>
          <th>Cost</th>
        </tr>
      </thead>

      <tbody>
	 { costs.map(costData => (
          <tr key={costData.id} className="dashboard--row">

            <td className="dashboard--column__name">{costData.name}</td>

            <td className="dashboard--column__status">
              <span className="dashboard--column__status-text">{ getStatus(costData.status) }</span>
               { showStopButton(costData.status) && (
               <div>
                  <ButtonStop env={ costData } uiEventBus={uiEventBus}/>
               </div>
               )}
               { showTerminateButton(costData.status) && (
                <div>
                  <ButtonTerminate env={ costData } uiEventBus={uiEventBus}/>
                </div>
              )}
              </td>
              <td className="dashboard--column__cost">

              {totalCosts[costData.id] === 0 && (
                <div className="right-align">$0.00</div>
              )}

              {totalCosts[costData.id] !== 0 && (
                <details>
                  <summary>
                    <div className="dashboard--details-summary">
                      <div>{costData.id}</div>
                      <div className="dashboard--total__env">${ (totalCosts[costData.id] || 0).toFixed(2) }</div>
                    </div>
                  </summary>

                  {getCostBreakdown(costData.costs).map(item => (
                    <div className="dashboard--cost-breakdown-container" key={ item.name }>
                      <div>&bull;</div>
                      <div>{ item.name }</div>
                      <div className="right-align">${ item.cost.toFixed(2) }</div>
                    </div>
                  ))}

                </details>
              )}

            </td>

          </tr>
        ))}
      </tbody>

    </table>
  );
}

function EnvCostTable({uiEventBus}) {
  const [costs, getCosts] = useEnvCosts();
  const totalCosts = useEnvTotalCosts(costs);

  useEffect(() => {
    getCosts();
  }, [])


  return (
    <Container className="mt3 mb4">

      {costs.length !== 0 ? (
        <Content costs={ costs } totalCosts={ totalCosts } uiEventBus={uiEventBus} />
      ): (
         <Segment className="bold">No cost data to show</Segment>
      )}  

    </Container>
  );
}

export default EnvCostTable;
