
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { getRestorationRequests } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { StudyRestorations } from './StudyRestorations';

const StudyRestorationsStore = BaseStore.named('StudyRestorationsStore')
  .props({
    currentPage: 1,
    pageSize: 10,
    total: 0,
    progress: 0,
    studyId: types.identifier,
    restorations: types.array(StudyRestorations),
    visibleRecords: types.optional(types.array(StudyRestorations), []),
    tickPeriod: 5 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad({ page = 1, pageSize = 10, reload = false } = {}) {
        self.currentPage = page;
        self.pageSize = pageSize;

        const newRestorations = await getRestorationRequests(self.studyId);
        if (reload) {
          if (!self.restorations || !_.isEqual(self.restorations, newRestorations)) {
            self.runInAction(() => {
              self.restorations = newRestorations;
              self.total = self.restorations.length;
              const num = self.currentPage * self.pageSize;
              self.visibleRecords.replace(_.cloneDeep(self.restorations.slice(num - self.pageSize, num)));
              self.progress = 100;
            });
          }
        } else {
          self.runInAction(() => {
            self.progress = 0;
            const num = self.currentPage * self.pageSize;
            self.visibleRecords.replace(_.cloneDeep(self.restorations.slice(num - self.pageSize, num)));
            self.progress = 100;
          });
        }
      },

      cleanup: () => {
        superCleanup();
        self.restorations.clear();
        self.currentPage = 1;
        self.total = 0;
        self.visibleRecords = [];
        self.progress = 0;
      },
    };
  })

  .views(self => ({
    get totalPages() {
      return self.total === 0 ? 1 : Math.ceil(self.total / self.pageSize);
    },
  }));

export { StudyRestorationsStore };
