/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';

import { addUser, getUsers, updateUser, deleteUser, addUsers, updateUserApplication, 
  accedeUserAgreement, updateUserLogin } from '../../helpers/api';
import { User } from './User';
import { BaseStore } from '../BaseStore';

const UsersStore = BaseStore.named('UsersStore')
  .props({
    users: types.optional(types.map(User), {}),
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const users = (await getUsers()) || [];
        self.runInAction(() => {
          users.forEach(user => {
            const userModel = User.create(user);
            const previous = self.users.get(userModel.id);
            if (!previous) {
              self.users.set(userModel.id, userModel);
            } else {
              previous.setUser(user);
            }
          });
        });
      },

      cleanup: () => {
        self.users.clear();
        superCleanup();
      },
      addUser: async user => {
        const addedUser = await addUser(user);
        self.runInAction(() => {
          // Added newly created user to users map
          const addedUserModel = User.create(addedUser);
          self.users.set(addedUserModel.id, addedUserModel);
        });
      },
      addUsers: async users => {
        await addUsers(users);
      },
      updateUser: async user => {
        const updatedUser = await updateUser(user);
        const userModel = User.create(updatedUser);
        const previousUser = self.users.get(userModel.id);
        applySnapshot(previousUser, updatedUser);
      },
      updateUserApplication: async user => {
        const res = await updateUserApplication(user);
        return res;
      },
      accedeUserAgreement: async user => {
	const updatedUser = await accedeUserAgreement(user);
	const userModel = User.create(updatedUser);
        const previousUser = self.users.get(userModel.id);
        applySnapshot(previousUser, updatedUser);    
      },
      deleteUser: async user => {
        await deleteUser(user);
      },
      updateUserLogin: async user => {
	const res = await updateUserLogin(user);
	return res;
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.users.size === 0;
    },

    get hasNonRootAdmins() {
      const nonRootAdmins = _.filter(self.list, user => user.isAdmin && !user.isRootUser);
      // console.log(nonRootAdmins);
      return !_.isEmpty(nonRootAdmins);
    },

    get hasNonRootUsers() {
      return !_.isEmpty(self.nonRootUsers);
    },

    get nonRootUsers() {
      return _.filter(self.list, user => !user.isRootUser);
    },

    get list() {
      const result = [];
      // converting map self.users to result array
      self.users.forEach(user => result.push(user));
      return result;
    },

    asSelectOptions({ nonClearables = [] } = {}) {
      const result = [];
      self.users.forEach(user =>
        result.push({
          value: user.id,
          label: user.longDisplayName,
          clearableValue: !nonClearables.includes(user.id),
        }),
      );
      return result;
    },

    asDropDownOptions({ status = 'active' } = {}) {
      const result = [];
      self.users.forEach(user => {
        if (user.status === status) {
          result.push({
            key: user.id,
            value: user.id,
            text: user.longDisplayName,
          });
        }
      });
      return result;
    },

    asEncodedDropDownOptions({ status = 'active' } = {}) {
      const result = [];
      self.users.forEach(user => {
        if (user.status === status) {
          result.push({
            key: user.encodedIdentifierStr,
            value: user.encodedIdentifierStr,
            text: user.longDisplayName,
          });
        }
      });
      return result;
    },

    asUserObject(userIdentifier) {
      if (userIdentifier) {
        const user = self.users.get(userIdentifier.id);
        return user || User.create({ username: userIdentifier.username, ns: userIdentifier.ns }); // this could happen in the employee is no longer active or with the company
      }
      return undefined;
    },

    asUserObjectByNsUsername(ns, username) {
      if (ns && username) {
		const user = User.create({username, ns}); //edited by Reagan
		const search = self.users.get(user.id);
		return search || User.create({ username, ns }); // this could happen in the employee is no longer active or with the company
      }
      return undefined;
    },

    asUserObjects(userIdentifiers = []) {
      const result = [];
      userIdentifiers.forEach(userIdentifier => {
        if (userIdentifier) {
          const user = self.users.get(userIdentifier.id);
          if (user) {
            result.push(user);
          } else {
            result.push(User.create(getSnapshot(userIdentifier)));
          } // this could happen in the employee is no longer active or with the company
        }
      });

      return result;
    },
  }));

function toUserIds(userObjects) {
  return _.map(userObjects, user => user.id);
}

function toLongNames(userObjects) {
  return _.map(userObjects, user => user.longDisplayName);
}

function toLongName(object) {
  if (object) {
    return object.longDisplayName;
  }
  return 'Unknown';
}

function registerModels(globals) {
  globals.usersStore = UsersStore.create({}, globals);
}

export { UsersStore, toUserIds, toLongNames, toLongName, registerModels };
