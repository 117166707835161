

/* eslint-disable import/prefer-default-export */
import { types } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';

// ==================================================================
// Study Publish Request
// ==================================================================

const StudyRestorations = types
  .model('StudyRestorations', {
    studyId: types.string,
    requestId: types.string,
    type: types.string,
    expirationDate: types.string,
    createdAt: '',
    requestor: UserIdentifier,
    updatedAt: '',
    collections: types.array(types.string),
  });

export { StudyRestorations };
