import React from 'react';
import { Grid, Header, Icon, Segment, Tab } from 'semantic-ui-react';
import { observer, inject, Observer } from 'mobx-react';
import { withRouter } from '../../helpers/routing';
import EnvSummaryPane from './EnvSummaryPane';
import EnvCostTable from './EnvCostTable';

class Dashboard extends React.Component {
   getUiEventBus() {
     return this.props.uiEventBus;
   }
	
   render() {
      const panes = [
      {
        menuItem: 'Chart View',
        render: () => (
          <Tab.Pane attached={false}>
            <Observer>
               {() => this.renderChartView()}</Observer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Table View',
        render: () => (
          <Tab.Pane attached={false}>
            <Observer>
               {() => this.renderTableView()}</Observer>
          </Tab.Pane>
        ),
      }
     ];
	   
     return (
      <>
	<Grid className="mt0">
          <Grid.Column width={16}>
            <Segment raised className="mt3">
              <Header as="h2" color="grey" className="mt1">
                <Icon name="dashboard" className="align-top" />
	        Dashboard
              </Header>
              <Tab className="mt2" menu={{ attached: true, tabular: true }} panes={panes} />
            </Segment>
          </Grid.Column>
        </Grid>
      </>
    );
   }	   

   renderChartView() {
      return <EnvSummaryPane />;
   }

   renderTableView() {
      return <EnvCostTable uiEventBus={this.getUiEventBus()} />;
   }
}

export default inject('uiEventBus')(withRouter(observer(Dashboard)));
