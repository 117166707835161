/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { inject, observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import { Header, List, Checkbox, Tab, Grid, Label, Icon, Segment, Dropdown, Table } from 'semantic-ui-react';
import Dotdotdot from 'react-dotdotdot';
import TimeAgo from 'react-timeago';

import RowBase from './RowBase';
import StudyFilesTable from './StudyFilesTable';
import StudyPermissionsTable from './StudyPermissionsTable';
import RequestAccess from './RequestAccess';
// import UploadStudyFiles from './UploadStudyFiles';
import UploadApiDataFiles from './UploadApiDataFile'; // add 2359 - add api study type
//import UploadRequest from './UploadRequest';
import ArchiveStudy from './ArchiveStudy';
import ChangeArchival from './ChangeArchival';
import RestoreStudy from './RestoreStudy';
import EditStudy from './EditStudy';
import DeleteStudy from './DeleteStudy';
import EditOutputStudy from './EditOutputStudy';
import UpgradeStudy from './UpgradeToSecureStudy';
import RequestPublishStudy from './RequestPublishStudy';
import TagEditor from '../helpers/tag-set/TagEditor';
import ComponentSwitch from '../helpers/ComponentSwitch';
import { swallowError } from '../../helpers/utils';
import { Study as StudyModel } from '../../models/studies/Study';
import { StudiesStore } from '../../models/studies/StudiesStore';
import StudyPublishRequestsTable from './StudyPublishRequestsTable';
import StudyRestorationRequestsTable from './StudyRestorationRequestsTable';
import DataCollectionPane from './DataCollectionPane';
import StudyCollectionsList from './StudyCollectionsList';
import StudyCollectionsTable from './StudyCollectionsTable';
import ByWithProfile from '../helpers/ByWithProfile';

// edited by 2359 - modified to handle fine grained permission
// edited by leeas - change display for upload study
// edit 2359 - add api study type
// edit 2359 - add initial support for data collections
class StudyRow extends RowBase {
  constructor(props) {
    super(props);
	this.userStudiesStore = this.props.studiesStore;
    makeObservable(this, {
		userStudiesStore: observable
	});

    this.getUserStudiesStore();
    this.getStudyCollectionsStore();
  }

  componentDidMount() {
    swallowError(this.props.uploadDirStore.load(this.props.uploadDirStore.unameWithNs));
  }

  getUserStudiesStore() {
    /*
	if(!this.userStudiesStore){
		this.userStudiesStore = this.props.studiesStore;
	}
	swallowError(this.userStudiesStore.load());
	*/
    if (!this.userStudiesStore) {
      this.userStudiesStore = StudiesStore.create({ loadMethod: 'getMyStudies' });
    }
    swallowError(this.userStudiesStore.load());
  }

  getStudyCollectionsStore() {
    if (!this.studyCollectionsStore) {
      this.studyCollectionsStore = this.props.study.getCollectionsStore(
        this.props.study.isAccessible() ? 'accessible' : 'not-accessible',
      );
    }
    swallowError(this.studyCollectionsStore.load());
  }

  handleFileSelection = study => {
    const canAdd =
      (!study.isOutput || (study.isOutput && study.isSharing && study.outputAvailable())) &&
      !study.deleted &&
      study.isAvailable();
    if (canAdd) {
      const selection = this.props.filesSelection;
      if (selection.hasFile(study.id)) {
        selection.deleteFile(study.id);
      } else {
        const { id, name, description, isSecure, category } = study;
        if (!study.noAccess() && !study.accessRequested()) {
          // bugfix - don't allow selection of studies with no access
          const accessStatus = study.noAccess() ? 'denied' : 'approved';
          // TODO: actually do different statuses?

          // if user is admin of study, disregard collections and allow user access to the whole study
          // else if study has collections, we'll need to add those that user has access to into the prefix list
          if (study.adminAccess()) {
            selection.setFile({ id, name, description, accessStatus, isSecure, category });
          } else {
            const collections = this.props.study.collections;
            // console.log(`collections: ${JSON.stringify(collections)}`);
            if (study.collectionAccess() && collections.length > 0) {
              // console.log('here');
              selection.setFile({
                id,
                name,
                description,
                accessStatus,
                isSecure,
                category,
                prefix: collections.map(col => col.collectionId),
              });
            } else {
              selection.setFile({ id, name, description, accessStatus, isSecure, category });
            }
          }
        }
      }
    }
  };

  getTabPanes(study) {
    const rdsArns = study.resources
      .filter(resource => resource.arn.startsWith('arn:aws:rds-db:'))
      .map(resource => resource.arn);
    // const filesStore = study.getFilesStore();
    // filesStore.load({page: 1, reload: true});

    let panes = [];

    panes = panes.concat([
      {
        menuItem: 'Description',
        render: () => (
          <Tab.Pane basic attached={false}>
            {this.renderDescriptionPane(study)}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Metadata',
        render: () => (
          <Tab.Pane basic attached={false}>
            {this.renderMetadataPane(study)}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Study Details',
        render: () => (
          <Tab.Pane basic attached={false}>
            <div className="ml1">
              <Header as="h2">Attributes</Header>

              <div className="ml2">
                <b>Project ID</b>: {study.projectId}
              </div>
              <br />

              {rdsArns.length !== 0 && (
                <div className="ml2">
                  <b>DB User ARNs</b>:
                  <List bulleted>
                    {rdsArns.map(arn => (
                      <List.Item>{arn}</List.Item>
                    ))}
                  </List>
                </div>
              )}
            </div>

            {/* Only users who have read access to the study can see the list of files in the study */}
            {study.uploadLocationEnabled && !study.noAccess() && !study.accessRequested() && (
              <StudyFilesTable study={study} />
            ) /* filesStore={filesStore} */}
          </Tab.Pane>
        ),
      },
    ]);

    // Add admin-visible tabs for organization study
    if (
      (!study.isOutput && study.category === 'Organization' && study.adminAccess()) ||
      (study.isOutput && study.isSharing && study.category === 'Organization' && study.adminAccess())
    ) {
      panes.push({
        menuItem: 'Permissions',
        render: () => (
          <Tab.Pane basic attached={false}>
            <StudyPermissionsTable study={study} />
          </Tab.Pane>
        ),
      });

      panes.push({
        menuItem: 'Publish Requests',
        render: () => (
          <Tab.Pane basic attached={false}>
            {this.renderPublishRequestsPane(study)}
          </Tab.Pane>
        ),
      });
    }

    // add study collection pane only if study is not deleted (archived studies are allowed)
    if (study.dataAvailable() && study.adminAccess() && study.category !== 'API Data' && !study.isOutput) {
      panes.push({
        menuItem: 'Collections',
        render: () => (
          <Tab.Pane basic attached={false}>
            {this.renderStudyCollectionsPane(study)}
          </Tab.Pane>
        ),
      });
    }

    // add archival information pane only if study is archived
    if ((study.archived && !study.isOutput && study.category === 'Organization') ||
      (study.archived && study.isOutput && study.isSharing && study.category === 'Organization')) {
      panes.push({
        menuItem: 'Archival Information',
        render: () => (
          <Tab.Pane basic attached={false}>
            {this.renderArchivalInformationPane(study)}
          </Tab.Pane>
        ),
      });
    }

    // add restoration information pane only if study is archived and has been restored before
    if (study.adminAccess() && (study.archived && !study.isOutput && study.category === 'Organization') ||
      (study.archived && study.isOutput && study.isSharing && study.category === 'Organization')) {
      panes.push({
        menuItem: 'Restoration Information',
        render: () => (
          <Tab.Pane basic attached={false}>
            {this.renderRestorationInformationPane(study)}
          </Tab.Pane>
        ),
      });
    }

    return panes;
  }

  getData() {
    return this.props.study;
  }

  getTagSet() {
    return this.props.tagSetsStore.getTagSet(StudyModel.name, this.getData().id);
  }

  getCollections = async studyId => {
    let collections = [];
    if (this.props.study.isAccessible()) {
      collections = await this.props.studiesStore.getCollections(studyId, 'accessible');
    } else {
      collections = await this.props.studiesStore.getCollections(studyId, 'not-accessible');
    }
    return collections;
  };

  // edit 2359 - add initial support for data collections
  renderDescriptionPane() {
    const data = this.getData();
    return (
      <Segment>
        <Dotdotdot clamp={3}>
          <div style={{ 'white-space': 'pre-line' }}>{data.description}</div>
        </Dotdotdot>
      </Segment>
    );
  }

  renderArchivalInformationPane() {
    const data = this.getData();
    return (
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Storage Class</Table.Cell>
            <Table.Cell><b>Archival started on</b></Table.Cell>
            <Table.Cell><b>Lockout ends on</b></Table.Cell>
            <Table.Cell><b>Minimum storage duration ends on</b></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{data.parseStorage()}</Table.Cell>
            <Table.Cell>{data.localTimezone()}</Table.Cell>
            <Table.Cell>{data.sevenDaysFromArchive()}</Table.Cell>
            <Table.Cell>{data.minimumStorageDuration()}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
	
  renderDataCollectionPane(study) {
    const studyStore = this.props.studiesStore.getStudyStore(study.id);
    return (
      <DataCollectionPane
        study={study}
        studyStore={studyStore}
        handlePrefixSelection={this.handlePrefixSelection}
        getStudyCollections={this.getCollections}
      />
    );
  }

  renderMetadataPane(study) {
    return <TagEditor tagSet={this.getTagSet()} study={study} admin={this.getData().adminAccess()} />;
  }

  // edit 2359 - add api study type
  renderHeader(study) {
    const selection = this.props.filesSelection;
    const isSelected = selection.hasFile(study.id);
    const by = () => <span className="ml1"><ByWithProfile by user={this.props.usersStore.asUserObjectByNsUsername(study.createdBy.ns, study.createdBy.username)}/> </span>;

    return (
      <Header as="h2" color="grey" className="mt0">
        <Grid>
          <Grid.Column width={12} onClick={() => this.handleFileSelection(study)}>
            <table>
              <tr>
                <td>
                  {study.isAvailable() &&
                    !study.deleted &&
	            (!study.archived || (study.archived && study.isRestored)) &&		  
                    ((!study.isOutput && !this.props.noPermission) || (study.isOutput && study.isSharing)) && (
                      <Checkbox className="mr1" checked={isSelected} />
                    )}
                  {study.isOutput && !study.isSharing && <Icon name="edit" />}
                  {study.category !== 'API Data' && !study.isSecure && <Icon name="folder" />}
                  {study.category === 'API Data' && <Icon name="world" />}
                  {study.isSecure && <Icon name="lock"/>}
                </td>
                <td>
                  <a rel="noopener noreferrer" target="_blank">
                    {study.name} <span className="pl2 font-size-small-9">(ID: {study.id})</span> 
                    <Header.Subheader className="fs-9">
                       created <TimeAgo date={study.createdAt} /> {by()}
                    </Header.Subheader>
                  </a>&nbsp;&nbsp;&nbsp;
                  {study.isSecure && (
                    <Label as="a" tag>
                      Secure
                    </Label>
                  )}
                  {study.category === 'API Data' && (
                    <Label as="a" tag>
                      API Data
                    </Label>
                  )}
	          {study.archived && (
                  	<Label as='b' color='grey' tag>
                  	  Archived
                  	</Label>)}
            	  {study.isRestored &&(
			<Label as='b' color='black' tag>
			  Restored
			</Label>)} 
                </td>
              </tr>
            </table>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {this.renderStudyActions(study)}
          </Grid.Column>
        </Grid>
        {!study.noAccess() &&
          !study.accessRequested() &&
          !study.adminAccess() &&
          study.collectionAccess() &&
	  !study.archived &&	
          this.renderCollectionsList(study)}
      </Header>
    );
  }

  // edit 2359 - add api study type
  renderStudyActions(study) {
    return (
      <div>
        {!study.isAvailable() && <Label>Not available</Label>}
        {study.isAvailable() && study.deleted && <Label>Deleted</Label>}
        {study.inUse && <Label>InUse</Label>}
        {study.inPending && <Label>Pending</Label>}
        {study.isAvailable() && !study.deleted && (
          <ComponentSwitch
            tests={[
              () =>
                ((!study.isOutput && study.noAccess()) || (study.isOutput && study.isSharing && study.noAccess())) && (
                  <RequestAccess study={study} onRequestAccess={() => {}} />
                ),
              () => study.accessRequested() && <Label>Access Requested</Label>,
            ]}
            fallback={null}
          />
        )}
        {study.isAvailable() &&
          !study.deleted &&
          !study.noAccess() &&
          (study.adminAccess() ||
            (!study.isOutput && (study.uploadAvailable() || study.isUpload) && !study.archived) ||
            (study.isOutput && study.isSharing && (study.uploadAvailable() || study.isUpload))) && (
            <Dropdown item icon="ellipsis vertical" simple direction="left">
              <Dropdown.Menu>
                {study.adminAccess() && (
                  <Dropdown.Item>
                    <EditStudy study={study} studiesStore={this.props.studiesStore} />
                  </Dropdown.Item>
                )}
                {study.adminAccess() &&
                  !study.secureAccess() &&
                  (!study.isOutput || (study.isOutput && study.isSharing)) &&
                  (!study.archived || (study.archived && study.isRestored && study.isSevenDays())) &&
		   study.category === 'Organization' && (
                    <Dropdown.Item>
                      <UpgradeStudy study={study} studiesStore={this.props.studiesStore} />
                    </Dropdown.Item>
                  )}
		  {study.adminAccess() &&
                  (!study.isOutput || (study.isOutput && study.isSharing)) &&
                  !study.archived &&
                  study.category === 'Organization' && (
				  <Dropdown.Item>
				    <ArchiveStudy study={study} studiesStore={this.props.studiesStore} />
				  </Dropdown.Item>
				)}
		  {study.adminAccess() && 
                  (!study.isOutput || (study.isOutput && study.isSharing)) &&
                   (study.archived && study.isSevenDays() && study.isValidTransition()) &&
                    study.category === 'Organization' && (
					<Dropdown.Item>
					  <ChangeArchival study={study} studiesStore={this.props.studiesStore} />
					</Dropdown.Item>
				)}
                  {study.adminAccess() &&
                  (!study.isOutput || (study.isOutput && study.isSharing)) &&
                   (study.archived && study.isSevenDays()) &&
                    study.category === 'Organization' && (
					 <Dropdown.Item>
					   <RestoreStudy study={study} studiesStore={this.props.studiesStore} />
					 </Dropdown.Item>
				 )}   
                  {study.category === 'Organization' && (
                    <EditOutputStudy
                      study={study}
                      studiesStore={this.props.studiesStore}
                      userStudiesStore={this.userStudiesStore}
                    />
                  )}
                  {study.adminAccess() && (!study.isOutput || (study.isOutput && study.isSharing)) && 
                  (!study.archived || (study.archived && study.isSevenDays())) && (				            <Dropdown.Item>
                      <DeleteStudy study={study} />
                    </Dropdown.Item>
                  )}
                {study.adminAccess() &&
                  ((!study.isOutput && (study.uploadAvailable() || study.isUpload)) ||
                    (study.isOutput && study.isSharing && (study.uploadAvailable() || study.isUpload))) &&       
		    !study.archived && (
                    <Dropdown.Divider />
                  )}
                {study.adminAccess() && study.category === 'API Data' && <UploadApiDataFiles study={study} />}
                {(study.adminAccess() || (!study.adminAccess() && study.isUpload)) &&
                  study.category !== 'API Data' &&
                  (!study.isOutput || (study.isOutput && study.isSharing)) && 
                  !study.archive && (
                    <RequestPublishStudy study={study} studiesStore={this.props.studiesStore} />
                  )}
              </Dropdown.Menu>
            </Dropdown>
          )}
      </div>
    );
  }

  // add 2359 - add initial support for data collections
  handlePrefixSelection = (study, selectedprefix) => {
    const selection = this.props.filesSelection;
    if (selection.hasFile(study.id)) {
      if (selection.hasPrefix(study.id, selectedprefix)) {
        selection.deletePrefix(study.id, selectedprefix);
      } else {
        selection.setPrefix(study.id, selectedprefix);
      }
    } else {
      const { id, name, description, isSecure } = study;
      const accessStatus = study.noAccess() ? 'denied' : 'approved';
      // TODO: actually do different statuses?
      selection.setFile({ id, name, description, accessStatus, isSecure, prefix: [selectedprefix] });
    }
  };

  renderPublishRequestsPane(study) {
    return <StudyPublishRequestsTable study={study} />;
  }

  renderCollectionsList(study) {
    return (
      <StudyCollectionsList
        study={study}
        handlePrefixSelection={this.handlePrefixSelection}
        isAdmin={study.adminAccess()}
      />
    );
  }

  renderStudyCollectionsPane(study) {
    return <StudyCollectionsTable study={study} />;
  }

  renderRestorationInformationPane(study) {
    return <StudyRestorationRequestsTable study={study} />;
  }	
}

// RowBase is already an observer
export default inject('filesSelection', 'tagSetsStore', 'userStore', 'usersStore')(observer(StudyRow));
