import React from 'react';
import _ from 'lodash';
import { Modal, Dropdown as SemanticDropdown, Table, Input as SemanticInput, Button, Header as BaseHeader } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { swallowError } from '../../helpers/utils';

import Dropdown from '../helpers/fields/DropDown';
import Form from '../helpers/fields/Form';
import Header from '../helpers/fields/Header';
import DateField from '../helpers/fields/DateField';

import { getRestoreStudyForm } from '../../models/forms/RestoreStudyForm';
import { displayError, displaySuccess } from '../../helpers/notification';

// expected props
// - study
class RestoreStudy extends React.Component {
  modalOpen = false;
  //selectedRetrieval = '';
  isCollectionsDisabled = true;
  //selectedCollections = '';
  //expiry = 0;

  constructor(props) {
    super(props);
    // selectedCollections: observable,	 
    // selectedRetrieval: observable,
    // expiry: observable, 
    makeObservable(this, {
      modalOpen: observable,
      isCollectionsDisabled: observable,
    });
    this.study = props.study;
    this.permissionsStore = props.study.getPermissionsStore();
    this.publishRequestStore = props.study.getPublishRequestsStore();
    this.studyCollectionsStore = props.study.getCollectionsStore();
    this.componentStore = observable({
      showRestoreModal: false,
      showLoader: false,
    });
    //        runInAction(() => {
    //          this.selectedRetrieval = '';
    //          this.isCollectionsDisabled = true;
    //          this.selectedCollections = '';
    //          this.expiry = 0;
    //        });
    this.onChangeValue = this.onChangeValue.bind(this);
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.isCollectionsDisabled = true;
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => { };

  handleFormSubmission = async form => {
    const studyValues = form.values();
    const studyId = this.props.study.id;
    const isCollectionsDisabled = this.isCollectionsDisabled;
    const inputStudy = {
      ...studyValues,
      id: studyId,
      isCollectionsDisabled,
    };
    console.log(inputStudy);
    try {
      let successMessage = '';
      // Restore study, clear form, and close modal
      if (isCollectionsDisabled) {
        await this.props.studiesStore.restoreByStudy(inputStudy);
        successMessage = `The entire study is now being restored via ${studyValues.selectedRetrieval.toLowerCase()} retrieval type.`;
      } else {
        await this.props.studiesStore.restoreByCollections(inputStudy);
        successMessage = `The collections for the study are now being restored via ${studyValues.selectedRetrieval.toLowerCase()} retrieval type.`;
      }
      displaySuccess(successMessage);
      form.clear();
      this.cleanModal();
    } catch (error) {
      displayError(error);
    }
  };

  handleInstantRestore = async () => {
    runInAction(() => {
      this.componentStore.showRestoreModal = false;
      this.componentStore.showLoader = true;
    });
    // submit archive study request
    try {
      await this.props.studiesStore.instantRestore(this.props.study.id);
      const successMessage = 'The entire study has been restored.';
      displaySuccess(successMessage);
      this.cleanModal();

    } catch (error) {
      displayError(error);
    }

    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  componentDidMount() {
    swallowError(this.permissionsStore.load());
    swallowError(this.publishRequestStore.load());
    swallowError(this.studyCollectionsStore.load());
    this.permissionsStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.permissionsStore.stopHeartbeat();
  }

  renderStorageInformation() {
    let res;
    if (this.study.archiveClass === 'GLACIER') {
      res = (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Retrieval Type</Table.HeaderCell>
              <Table.HeaderCell>Retrieval Time</Table.HeaderCell>
              <Table.HeaderCell>Cost (per GB)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Standard</Table.Cell>
              <Table.Cell>3-5 hours</Table.Cell>
              <Table.Cell>$0.012</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Bulk</Table.Cell>
              <Table.Cell>5-12 hours</Table.Cell>
              <Table.Cell>Free</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );
    }
    if (this.study.archiveClass === 'DEEP_ARCHIVE') {
      res = (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Retrieval Type</Table.HeaderCell>
              <Table.HeaderCell>Retrieval Time</Table.HeaderCell>
              <Table.HeaderCell>Cost (per GB)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Standard</Table.Cell>
              <Table.Cell>~12 hours</Table.Cell>
              <Table.Cell>$0.024</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Bulk</Table.Cell>
              <Table.Cell>~48 hours</Table.Cell>
              <Table.Cell>$0.005</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );
    }
    return res;
  }

  onChangeValue(event) {
    runInAction(() => {
      this.isCollectionsDisabled = event.target.value === 'true';
    });
  }

  renderCollectionsOptions() {
    const collections = this.studyCollectionsStore.list;

    const collectionsOptions = (collection) => {
      return (
        { key: collection.collectionId, text: collection.collectionName, value: collection.collectionId }
      );
    };

    const res = _.map(collections, (collection) => collectionsOptions(collection));
    return res;
  }

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Restore Study
          </BaseHeader>
          <div className="mx3 animated fadeIn">
            {this.renderRestoreStudyForm()}
          </div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    return (
      <SemanticDropdown.Item onClick={action(() => {
        this.modalOpen = true;
      })}>
        Restore Study
      </SemanticDropdown.Item>);
  }

  renderInstantRestoreModal() {
    return (
      <>
        <p>This will restore the selected study data. You will only be able to access the selected study data once they have been restored.</p>
        <p>If the data you wish to restore is currently undergoing restoration, this restoration request will fail.</p>
        <p>Restoration of this study will be instantaneous and will not incur a retrieval charge.</p>
        <Button className="ml2 mb3" floated="right" color="blue" icon onClick={this.handleInstantRestore}>
          Restore Study
        </Button>
        <Button className="mb3" floated="right" onClick={this.cleanModal}>
          Cancel
        </Button>
      </>
    );
  }

  renderRestoreStudyForm() {
    const form = getRestoreStudyForm();
    const storageInformation = this.renderStorageInformation();
    const today = new Date();
    // Minimum expiry date
    const formattedDate = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
      }-${`0${today.getDate() + 1}`.slice(-2)}`;
    // Maximum expiry date
    const maxDate = `${today.getFullYear() + 1}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
      }-${`0${today.getDate()}`.slice(-2)}`;
    // Default expiry date
    today.setDate(today.getDate() + 60);
    const afterSixtyDaysDate = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
      }-${`0${today.getDate()}`.slice(-2)}`;
    today.setDate(today.getDate() + 60);
    form.$('expiry').min = formattedDate;
    form.$('expiry').max = maxDate;
    form.$('expiry').value = afterSixtyDaysDate;

    return (
      <div>
        {this.study.archiveClass === 'GLACIER_IR' && this.renderInstantRestoreModal()}
        {this.study.archiveClass !== 'GLACIER_IR' && <Form
          form={form}
          onCancel={this.handleFormCancel}
          onSuccess={this.handleFormSubmission}
          onError={this.handleFormError}
        >
          {({ processing, _onSubmit, onCancel }) => {
            return (
              <>
                <p> This will restore the selected study data. You will only be able to access the selected study data once they have been restored. </p>
                <p> Note that the expiry date chosen would last until midnight SGT on that date.</p>
                {storageInformation}
                <a href="https://aws.amazon.com/s3/pricing/"> For more information on S3 Glacier storage pricing</a>
                <br />
                <br />
                <Dropdown field={form.$('selectedRetrieval')} options={retrievalOptions} fluid selection />
                <Header field={form.$('isCollectionsDisabled')} />
                <SemanticInput type="radio" name="retrieval" onChange={this.onChangeValue} defaultChecked /> Study &nbsp;&nbsp;
                <SemanticInput type="radio" name="retrieval" onChange={this.onChangeValue} disabled={this.renderCollectionsOptions().length === 0} /> Collections
                <Dropdown field={form.$('selectedCollections')} options={this.renderCollectionsOptions()} disabled={this.isCollectionsDisabled} fluid multiple selection defaultValue={[]} />
                <DateField field={form.$('expiry')} />
                <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                  Restore Study
                </Button>
                <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                  Cancel
                </Button>
              </>
            );
          }}
        </Form>}
      </div>
    );
  }
}

const retrievalOptions = [
  { key: 'Standard', text: 'Standard', value: 'STANDARD' },
  { key: 'Bulk', text: 'Bulk', value: 'BULK' },
];

export default observer(RestoreStudy);
