import React from 'react';
import { Modal, Dimmer, Loader, Dropdown, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { swallowError } from '../../helpers/utils';

import ConfirmationModal from '../helpers/ConfirmationModal';
import { displayError, displaySuccess } from '../../helpers/notification';
import lifecycleTransition from '../../images/lifecycle_transition.png';

//const INSTANT_RETRIEVAL = 'GLACIER_IR';
const FLEXIBLE_RETRIEVAL = 'GLACIER';
const DEEP_ARCHIVE = 'DEEP_ARCHIVE';
// expected props
// - study
class ChangeArchival extends React.Component {
  constructor(props) {
    super(props);
    this.study = props.study;
    this.permissionsStore = props.study.getPermissionsStore();
    this.publishRequestStore = props.study.getPublishRequestsStore();
    this.componentStore = observable({
      showArchiveModal: false,
      showLoader: false,
    });
    this.state = {
      previousClass: this.study.parseStorage(),
      selectedClass: '',
    };
  }

  componentDidMount() {
    swallowError(this.permissionsStore.load());
    swallowError(this.publishRequestStore.load());
    this.permissionsStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.permissionsStore.stopHeartbeat();
  }

  isValidTransition(selectedClass) {
    let res = false;
    if (selectedClass === FLEXIBLE_RETRIEVAL) {
      res = this.state.previousClass === 'Instant Retrieval';
    } else if (selectedClass === DEEP_ARCHIVE) {
      res = this.state.previousClass === 'Instant Retrieval' || this.state.previousClass === 'Flexible Retrieval';
    }
    return res;
  }

  async handleChangeArchival(selectedClass) {
    runInAction(() => {
      this.componentStore.showArchiveModal = false;
      this.componentStore.showLoader = true;
    });
    if (this.isValidTransition(selectedClass)) {
      // Perform change of archival
      try {
        // submit change study archive class request
        await this.props.studiesStore.archiveStudy(this.props.study.id, selectedClass);
        const successMessage = `The study is now being archived from ${this.state.previousClass} to ${this.props.study.parseStorage()}`;
        displaySuccess(successMessage);
        this.state.selectedClass = ''
      } catch (e) {
        displayError(e);
      }
    } else {
      displayError("Invalid transition of archival class!");
    }

    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  renderModal() {
    const message = (
      <Modal.Description>
        <p>
          <h3>Current archive class: {this.state.previousClass} </h3>
          <h4><b style={{ color: 'red' }}>WARNING:</b> If the current archived study has not fulfilled the minimum storage duration for its type,
            you will still incur the cost for this minimum storage duration.</h4>
          This study will be transferred to another archival class. Please note that:
          <ol>
            <li>All existing users will have their access permissions revoked</li>
            <li>All existing workspaces will have access to the study revoked</li>
            <li>All existing upload access for the study will be revoked</li>
            <li>All existing download keys for the study will be revoked</li>
          </ol>
        </p>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S3 Glacier Storage Classes</Table.HeaderCell>
              <Table.HeaderCell>Instant Retrieval</Table.HeaderCell>
              <Table.HeaderCell>Flexible Retrieval</Table.HeaderCell>
              <Table.HeaderCell>Deep Archive</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Storage Pricing Per Month</Table.Cell>
              <Table.Cell>$0.005 per GB</Table.Cell>
              <Table.Cell>$0.0045 per GB</Table.Cell>
              <Table.Cell>$0.002 per GB</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Minimum Storage Duration</Table.Cell>
              <Table.Cell>90 days</Table.Cell>
              <Table.Cell>90 days</Table.Cell>
              <Table.Cell>180 days</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Retrieval Time / Cost</Table.Cell>
              <Table.Cell>
                ~Milliseconds (No restoration needed) <br />/ $0.03 per GB
              </Table.Cell>
              <Table.Cell>
                <ul>
                  <li>1-5 minutes / $0.036 per GB (Expedited)</li>
                  <li>
                    3-5 hours <br />/ $0.012 per GB (Standard)
                  </li>
                  <li>
                    5-12 hours / Free <br />
                    (Bulk)
                  </li>
                </ul>
              </Table.Cell>
              <Table.Cell>
                <ul>
                  <li>~12 hours / $0.024 per GB (Standard)</li>
                  <li>~48 hours / $0.005 per GB (Bulk)</li>
                </ul>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Recommended Use Cases</Table.Cell>
              <Table.Cell>Archives that need immediate access</Table.Cell>
              <Table.Cell>Archives that require access within the day</Table.Cell>
              <Table.Cell>Archives that have known retrieval dates etc. audits</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <a href="https://aws.amazon.com/s3/pricing/"> For more information on S3 Glacier storage pricing</a>
        <br />
        The storage classes can transition in the following order:
        <br />
        <img src={lifecycleTransition} alt="Lifecycle Transition" style={{ bottom: '0', maxWidth: '100%' }} />
        <h4><b style={{ color: 'red' }}>WARNING:</b> You will be unable to perform any other actions except for editing of the study information for the next 7 days.</h4>
        <Dropdown
          fluid
          selection
          options={this.createSelectItems()}
          placeholder="Select Storage Class"
          onChange={(e, { value }) => {
            this.setState({ selectedClass: value });
          }}
        />
        <p>
          <br />
          Are you sure you want to proceed?
        </p>
      </Modal.Description>
    );
    return (
      <ConfirmationModal
        open={this.componentStore.showArchiveModal}
        header="Change Archival"
        message={message}
        onConfirm={() => {
          const msg = this.state.selectedClass;
          this.handleChangeArchival(msg);
        }}
        onCancel={() => {
          runInAction(() => {
            this.state.selectedClass = '';
            this.componentStore.showArchiveModal = false;
          });
        }}
      />
    );
  }

  render() {
    if (!this.publishRequestStore.hasOngoing()) {
      return (
        <>
          <Dimmer active={this.componentStore.showLoader}>
            <Loader />
          </Dimmer>
          <Dropdown.Item
            onClick={() => {
              runInAction(() => {
                this.componentStore.showArchiveModal = true;
              });
            }}
          >
            Change Archival
          </Dropdown.Item>

          {this.renderModal()}
        </>
      );
    }
    return null;
  }

  createSelectItems() {
    const items = [];
    if (this.study.archived && this.study.archiveClass !== FLEXIBLE_RETRIEVAL) {
      items.push({ key: 'Flexible Retrieval', text: 'Flexible Retrieval', value: FLEXIBLE_RETRIEVAL })
    }
    if (this.study.archived && this.study.archiveClass !== DEEP_ARCHIVE) {
      items.push({ key: 'Deep Archive', text: 'Deep Archive', value: DEEP_ARCHIVE })
    }
    return items;
  }
}

export default observer(ChangeArchival);
