import _ from 'lodash';
import React from 'react';
import { makeObservable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Dimmer, Loader, Table, Segment, Pagination, Header, Label } from 'semantic-ui-react';

import { swallowError, niceNumber } from '../../helpers/utils';
import { isStoreError, isStoreLoading, isStoreReloading, isStoreNew } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';


class StudyRestorationRequestsTable extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      loading: computed,
      results: computed,
      handlePaginationChange: action,
    });
    this.store = props.study.getRestorationsStore();
  }

  componentDidMount() {
    this.store.load({ page: 1, reload: true });
  }

  componentWillUnmount() {
  }

  get loading() {
    return isStoreLoading(this.store) || isStoreReloading(this.store);
  }

  get results() {
    return this.store.visibleRecords || [];
  }

  handlePaginationChange = (e, { activePage }) => {
    if (this.loading) return; // we ignore page selection when the store is loading/reloading
    const studyId = this.store.studyId;
    swallowError(
      this.store.load({
        page: activePage,
        reload: false,
        studyId,
      }),
    );
  };

  render() {
    let content;
    if (isStoreError(this.store)) {
      content = <ErrorBox error={this.store.error} />;
    } else if (isStoreLoading(this.store) || isStoreNew(this.store)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else {
      content = this.renderTable();
    }
    return content;
  }

  renderPagination() {
    const currentPage = this.store.currentPage;
    const totalPages = this.store.totalPages;
    if (totalPages < 2) return <div className="mb2" />;

    return (
      <Pagination
        activePage={currentPage}
        onPageChange={this.handlePaginationChange}
        totalPages={totalPages}
        floated="right"
      />
    );
  }

  renderTotal() {
    const count = this.store.total;
    const niceCount = niceNumber(count);

    return (
      <Header as="h3" className="color-grey mt1 mb0 flex-auto">
        Loaded Entries :{' '}
        <Label circular size="large">
          {niceCount}
        </Label>
      </Header>
    );
  }

  renderTable() {
    return (
      <div className="mt4">
        <Segment.Group>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
          <Segment clearing>
            {this.renderTotal()}
            {this.renderContent()}
          </Segment>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
        </Segment.Group>
      </div>
    );
  }

  renderContent() {
    const result = this.results;

    const renderRow = (restoration) => {
      let collectionsList;
      if (restoration.collections !== undefined) {
        collectionsList = restoration.collections.map((col) => <li>{col}</li>);
      }
      return (
        <Table.Row key={restoration.requestId}>
          <Table.Cell>{restoration.requestId}</Table.Cell>
          <Table.Cell>{restoration.type}</Table.Cell>
          <Table.Cell>{restoration.requestor.username}</Table.Cell>
          <Table.Cell>{restoration.createdAt}</Table.Cell>
          <Table.Cell>{restoration.expirationDate}</Table.Cell>
          {restoration.collections !== undefined && <Table.Cell><ul>{collectionsList}</ul></Table.Cell>}
        </Table.Row>
      );
    };

    return (
      <>
        <Dimmer.Dimmable dimmed={this.isProcessing}>
          <Dimmer active={this.isProcessing} inverted>
            <Loader size="big" />
          </Dimmer>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Request ID</Table.HeaderCell>
                <Table.HeaderCell width={2}>Type</Table.HeaderCell>
                <Table.HeaderCell width={2}>Requestor</Table.HeaderCell>
                <Table.HeaderCell width={2}>Date Requested</Table.HeaderCell>
                <Table.HeaderCell width={2}>Expiry Date</Table.HeaderCell>
                <Table.HeaderCell width={2}>Collections</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(result, (restoration) => renderRow(restoration))}
            </Table.Body>
          </Table>

        </Dimmer.Dimmable>
      </>
    );
  }

}

export default observer(StudyRestorationRequestsTable);
