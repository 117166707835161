/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { createForm } from '../../helpers/form';

const restoreStudyFields = {
  // General fields
  selectedRetrieval: {
    label: 'Retrieval Type',
    placeholder: 'Select Retrieval Type',
    rules: ['required', 'string', { in: ['STANDARD', 'BULK'] }],
  },
  isCollectionsDisabled: {
    label: 'Restoration Target',
    extra: {
      explain: 'Restore via the entire study or collections:',
    },
  },
  selectedCollections: {
    placeholder: 'Select Collections',
    rules: ['array', 'regex:/^[A-Za-z0-9-_,]+$/'],
  },
  expiry: {
    label: 'Expiry Date',
    value: '',
    rules: ['required', 'string'],
  },
};

const getRestoreStudyForm = () => {
  return createForm(restoreStudyFields);
};

export { getRestoreStudyForm }; // eslint-disable-line import/prefer-default-export
