/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getSnapshot, types } from 'mobx-state-tree';

import { grantStudyAccess, denyStudyAccess, getStudyAccessRequestFile } from '../../helpers/api';
import { displayError } from '../../helpers/notification';

const REQUESTED = 'requested';
const PROCESSING = 'processing';
const GRANTED = 'granted';
const DENIED = 'denied';

// edited by Reagan - added isArchived and selectedRetrieval
// edited by 2359 - added handling of different grant permissions
const AccessRequest = types
  .model('AccessRequest', {
    username: '',
    ns: '',
    status: types.enumeration('status', [REQUESTED, PROCESSING, GRANTED, DENIED]),
    isRead: types.boolean,
    isUpload: types.boolean,
    isMetaData: types.boolean,
    isArchived: types.boolean,
    selectedRetrieval: types.maybeNull(types.enumeration('retrieval', ['STANDARD', 'BULK'])),	  
    expiry: types.string,
    requestorComments: types.maybeNull(types.string),
    adminComments: types.maybeNull(types.string),
    collectionIds: types.array(types.string),
    uuid: types.maybeNull(types.string),
    requestFile: types.maybeNull(types.string),
    permissionType: types.maybeNull(types.string),
  })
  .actions(self => ({
    updateStatus(status) {
      self.status = status;
    },
    async grant(studyId, updateRequest) {
      self.status = PROCESSING;
      const { status, ...withoutStatus } = updateRequest;
      try {
        await grantStudyAccess(studyId, withoutStatus);
        self.updateStatus(GRANTED);
      } catch (e) {
        self.updateStatus(REQUESTED);
        displayError(`Error granting access for user '${self.username}'`, e);
      }
    },
    async deny(studyId, updateRequest) {
      self.status = PROCESSING;
      try {
        await denyStudyAccess(studyId, self.username, self.ns, updateRequest.adminComments);
        self.updateStatus(DENIED);
      } catch (e) {
        self.updateStatus(REQUESTED);
        displayError(`Error denying access for user '${self.username}'`, e);
      }
    },
    async downloadRequestFile(studyId, requestId) {
      try {
        const { data } = await getStudyAccessRequestFile(studyId, requestId);
        return data;
      } catch (e) {
        displayError(`Error retrieving file!`);
        return null;
      }
    },
  }))
  .views(self => ({
    get id() {
      return self.identifierStr;
    },
    get identifierStr() {
      return JSON.stringify(getSnapshot(self));
    },
    requested() {
      return !self.status || self.status === REQUESTED;
    },
    processing() {
      return self.status === PROCESSING;
    },
    granted() {
      return self.status === GRANTED;
    },
    denied() {
      return self.status === DENIED;
    },
  }));

export default AccessRequest;
