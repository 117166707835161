import React from 'react';
import { Modal, Dimmer, Loader, Dropdown, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { swallowError } from '../../helpers/utils';

import ConfirmationModal from '../helpers/ConfirmationModal';
import { displayError, displaySuccess } from '../../helpers/notification';

// expected props
// - study
class ArchiveStudy extends React.Component {
  constructor(props) {
    super(props);
    this.permissionsStore = props.study.getPermissionsStore();
    this.publishRequestStore = props.study.getPublishRequestsStore();
    this.componentStore = observable({
      showArchiveModal: false,
      showLoader: false,
    });
    this.state = {
      selectedClass: '',
    };
  }

  componentDidMount() {
    swallowError(this.permissionsStore.load());
    swallowError(this.publishRequestStore.load());
    this.permissionsStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.permissionsStore.stopHeartbeat();
  }

  async handleArchiveStudy(selectedClass) {
    runInAction(() => {
      this.componentStore.showArchiveModal = false;
      this.componentStore.showLoader = true;
    });

    // Perform archiving
    if (selectedClass !== '') {
      // submit archive study request
      try {
        await this.props.studiesStore.archiveStudy(this.props.study.id, selectedClass);

        const successMessage = `The study is now being archived to: ${this.props.study.parseStorage()}`;
        displaySuccess(successMessage);
        this.state.selectedClass = '';
      } catch (error) {
        displayError(error);
      }
    } else {
      displayError(`Please select a class to archive to!`);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  renderModal() {
    const message = (
      <Modal.Description>
        This will mark the study to be archived. Please note that:
        <ol>
          <li>All existing users will have their access permissions revoked</li>
          <li>All existing workspaces will have access to the study revoked</li>
          <li>All existing upload access for the study will be revoked</li>
          <li>All existing download keys for the study will be revoked</li>
        </ol>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S3 Glacier Storage Classes</Table.HeaderCell>
              <Table.HeaderCell>Instant Retrieval</Table.HeaderCell>
              <Table.HeaderCell>Flexible Retrieval</Table.HeaderCell>
              <Table.HeaderCell>Deep Archive</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Storage Pricing Per Month</Table.Cell>
              <Table.Cell>$0.005 per GB</Table.Cell>
              <Table.Cell>$0.0045 per GB</Table.Cell>
              <Table.Cell>$0.002 per GB</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Minimum Storage Duration</Table.Cell>
              <Table.Cell>90 days</Table.Cell>
              <Table.Cell>90 days</Table.Cell>
              <Table.Cell>180 days</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Retrieval Time / Cost</Table.Cell>
              <Table.Cell>
                ~Milliseconds (No restoration needed) <br />/ $0.03 per GB
              </Table.Cell>
              <Table.Cell>
                <ul>
                  <li>
                    3-5 hours <br />/ $0.012 per GB (Standard)
                  </li>
                  <li>
                    5-12 hours / Free <br />
                    (Bulk)
                  </li>
                </ul>
              </Table.Cell>
              <Table.Cell>
                <ul>
                  <li>~12 hours / $0.024 per GB (Standard)</li>
                  <li>~48 hours / $0.005 per GB (Bulk)</li>
                </ul>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Recommended Use Cases</Table.Cell>
              <Table.Cell>Archives that need immediate access</Table.Cell>
              <Table.Cell>Archives that require access within the day</Table.Cell>
              <Table.Cell>Archives that have known retrieval dates etc. audits</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <a href="https://aws.amazon.com/s3/pricing/"> For more information on S3 Glacier storage pricing</a>
        <h4><b style={{ color: 'red' }}>WARNING:</b> You will be unable to perform any other actions except for editing of the study information for the next 7 days.</h4>
        <Dropdown
          fluid
          selection
          options={storageOptions}
          placeholder="Select Storage Class"
          onChange={(e, { value }) => {
            this.setState({ selectedClass: value })
          }}
        />
        <p>
          <br />
          Are you sure you want to proceed?
        </p>
      </Modal.Description>
    );
    return (
      <ConfirmationModal
        open={this.componentStore.showArchiveModal}
        header="Archive Study"
        message={message}
        onConfirm={() => {
          const msg = this.state.selectedClass;
          this.handleArchiveStudy(msg);
        }}
        onCancel={() => {
          runInAction(() => {
            this.state.selectedClass = '';
            this.componentStore.showArchiveModal = false;
          });
        }}
      />
    );
  }

  render() {
    if (!this.publishRequestStore.hasOngoing()) {
      return (
        <>
          <Dimmer active={this.componentStore.showLoader}>
            <Loader />
          </Dimmer>
          <Dropdown.Item
            onClick={() => {
              runInAction(() => {
                this.componentStore.showArchiveModal = true;
              });
            }}
          >
            Archive Study
          </Dropdown.Item>

          {this.renderModal()}
        </>
      );
    }
    return null;
  }
}

const storageOptions = [
  { key: 'Instant Retrieval', text: 'Instant Retrieval', value: 'GLACIER_IR' },
  { key: 'Flexible Retrieval', text: 'Flexible Retrieval', value: 'GLACIER' },
  { key: 'Deep Archive', text: 'Deep Archive', value: 'DEEP_ARCHIVE' },
];

export default observer(ArchiveStudy);
