import React from 'react';
import { Icon, Label, Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { displayError } from '../../helpers/notification';
import { createForm } from '../../helpers/form';
import { deleteEnvironment } from '../../helpers/api';

import Form from '../helpers/fields/Form';

class EnvironmentTerminatePrompt extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  getMode() {
    return this.props.mode || 'env';
  }

  getEnvironment() {
    return this.props.environment;
  }

  getUiEventBus() {
    return this.props.uiEventBus;
  }

  getStore() {
    return this.props.environmentsStore;
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = _form => {
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async _form => {
    try {

      if (this.getMode() === 'dashboard') {
	  // showing the terminate prompt on dashboard page
	  const environment = this.getEnvironment(); 
	  await deleteEnvironment(environment.id);
	  environment.markAsTerminating();
	  await this.getUiEventBus().fireEvent('environment', environment);
	  this.cleanModal();    
	  window.location.reload(false);    
      } else { 
	  // showing the terminate prompt on environment detail page    
          const store = this.getStore();
          await store.deleteEnvironment(this.getEnvironment());
          await store.doLoad();
      }
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    const form = createForm({});
    const outputLocation = this.getEnvironment().isSecure ? 'secure output' : 'output study';
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Confirm Workspace Termination?
          </BaseHeader>
          <div className="mx3 animated fadeIn">
            <Form
              form={form}
              onCancel={this.handleFormCancel}
              onSuccess={this.handleFormSubmission}
              onError={this.handleFormError}
            >
              {({ processing, _onSubmit, onCancel }) => {
                return (
                  <>
                    Please ensure that all the data/scripts that need to be used beyond this workspace are saved in the{' '}
                    {outputLocation} folder mounted in your workspace. Failure to do so will result in{' '}
                    <span className="alert-text">irreversible</span> loss of these files.
                    <br />
                    <br />
                    Are you sure you want to continue?
                    <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                      Terminate!
                    </Button>
                    <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                      Cancel
                    </Button>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      color: 'red',
      className: 'cursor-pointer',
    };
    return (
      <Label
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        <Icon name="power off" />
        Terminate
      </Label>
    );
  }
}

export default inject('userStore')(observer(EnvironmentTerminatePrompt));
