
import { useEffect, useState } from 'react';


export default function (envCostData) {
  const [envData, setEnvData] = useState({});

  useEffect(() => {
    const totalCosts = {};
    envCostData.forEach(costData =>  {
      totalCosts[costData.id] = costData.costs.reduce((total, dailyCostByService) => {

          return total + Object.values(dailyCostByService.cost).reduce(
            (totalDay, serviceCost) => totalDay + (((typeof serviceCost.amount === 'string') ? parseFloat(serviceCost.amount) : serviceCost.amount) ?? 0),
            0,
          );

        }, 0);
    })
    setEnvData(totalCosts);

  }, [envCostData])

  return envData;
}
