import _ from 'lodash';
import { useState } from 'react';
import { getEnvironments, getEnvironmentCost, getIndexes, getS3Cost } from '../../helpers/api';


const DISPLAY_DURATION_DAYS=30;

export default function () {
  const [envData, setEnvData] = useState([]);

  async function getEnvData() {
    const allEnvironments = await getEnvironments();
    const indexes = await getIndexes();
    const s3cost = await getS3Cost(indexes[0].id, 30);

    // only show last N days of env data	  
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - DISPLAY_DURATION_DAYS);
    const envs = _.filter(allEnvironments, (env) =>  { return Date.parse(env.createdAt) >= startDate });

    const envCostIdToName = {};
    const envCostNameToIndex = {};
    envs.forEach(env => {
      envCostIdToName[env.id] = env.name;
      envCostNameToIndex[env.name] = env.indexId;
    });

    const envCostIds = Object.keys(envCostIdToName);
    const allEnvCosts = envCostIds.map(envId => {
      return getEnvironmentCost(envId, 30, true, false);
    });

    const envCosts = await Promise.all(allEnvCosts);
    const envCostItems = envs.map((item, index) => {
      item.costs = envCosts[index];
      const found = _.filter(s3cost, { envId: item.id });
      if (found) {
	   const s3costs = _.map(found, 'totalCost');   
	   const totalCost = s3costs.reduce((a, b) => a + b, 0);   
	   item.costs.push({"startDate":(new Date()).toISOString().slice(0,10),"cost":{"S3":{"amount":totalCost,"unit":"USD"}}});
      }
      return item;
    })

    setEnvData(envCostItems);
  }

  return [envData, getEnvData];
}
