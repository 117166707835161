/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { parseError, delay } from './utils';
import { apiPath } from './settings';

/* eslint-disable import/no-mutable-exports */
let config = {
  apiPath,
  fetchMode: 'cors',
  maxRetryCount: 4,
};

let token;
let decodedIdToken;
const authHeader = tok => ({ Authorization: `${tok}` });

function setIdToken(idToken, decodedToken) {
  token = idToken;
  decodedIdToken = decodedToken;
}

function getDecodedIdToken() {
  return decodedIdToken;
}

function forgetIdToken() {
  token = undefined;
  decodedIdToken = undefined;
}

function configure(obj) {
  config = { ...config, ...obj };
}

function fetchJson(url, options = {}, contentType = 'application/json', retryCount = 0) {
  // see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  let isOk = false;
  let httpStatus;

  const headers = {
    Accept: 'application/json',
    'Content-Type': contentType,
  };
  if (contentType === 'multipart/form-data') delete headers['Content-Type'];
  const body = '';
  const merged = {
    method: 'GET',
    cache: 'no-cache',
    mode: config.fetchMode,
    redirect: 'follow',
    body,
    ...options,
    headers: { ...headers, ...options.headers },
  };

  if (merged.method === 'GET') delete merged.body; // otherwise fetch will throw an error

  if (merged.params) {
    // if query string parameters are specified then add them to the URL
    // The merged.params here is just a plain JavaScript object with key and value
    // For example {key1: value1, key2: value2}

    // Get keys from the params object such as [key1, key2] etc
    const paramKeys = _.keys(merged.params);

    // Filter out params with undefined or null values
    const paramKeysToPass = _.filter(paramKeys, key => !_.isNil(_.get(merged.params, key)));
    const query = _.map(
      paramKeysToPass,
      key => `${encodeURIComponent(key)}=${encodeURIComponent(_.get(merged.params, key))}`,
    ).join('&');
    url = query ? `${url}?${query}` : url;
  }

  return Promise.resolve()
    .then(() => fetch(url, merged))
    .catch(err => {
      // this will capture network/timeout errors, because fetch does not consider http Status 5xx or 4xx as errors
      if (retryCount < config.maxRetryCount) {
        let backoff = retryCount * retryCount;
        if (backoff < 1) backoff = 1;

        return Promise.resolve()
          .then(() => console.log(`Retrying count = ${retryCount}, Backoff = ${backoff}`))
          .then(() => delay(backoff))
          .then(() => fetchJson(url, options, undefined, retryCount + 1));
      }
      throw parseError(err);
    })
    .then(response => {
      isOk = response.ok;
      httpStatus = response.status;
      return response;
    })
    .then(response => {
      if (_.isFunction(response.text)) return response.text();
      return response;
    })
    .then(text => {
      let json;
      try {
        if (_.isObject(text)) {
          json = text;
        } else {
          json = JSON.parse(text);
        }
      } catch (err) {
        if (httpStatus >= 400) {
          if (httpStatus >= 501 && retryCount < config.maxRetryCount) {
            let backoff = retryCount * retryCount;
            if (backoff < 1) backoff = 1;

            return Promise.resolve()
              .then(() => console.log(`Retrying count = ${retryCount}, Backoff = ${backoff}`))
              .then(() => delay(backoff))
              .then(() => fetchJson(url, options, retryCount + 1));
          }
          throw parseError({
            message: text,
            status: httpStatus,
          });
        } else {
          throw parseError(new Error('The server did not return a json response.'));
        }
      }

      return json;
    })
    .then(json => {
      if (_.isBoolean(isOk) && !isOk) {
        throw parseError({ ...json, status: httpStatus });
      } else {
        return json;
      }
    });
}

// ---------- helper functions ---------------

function httpApiGet(urlPath, { params } = {}) {
  return fetchJson(`${config.apiPath}/${urlPath}`, {
    method: 'GET',
    headers: authHeader(token),
    params,
  });
}

function httpApiPost(urlPath, { data, params } = {}) {
  return fetchJson(`${config.apiPath}/${urlPath}`, {
    method: 'POST',
    headers: authHeader(token),
    params,
    body: JSON.stringify(data),
  });
}

function httpApiPut(urlPath, { data, params } = {}) {
  return fetchJson(`${config.apiPath}/${urlPath}`, {
    method: 'PUT',
    headers: authHeader(token),
    params,
    body: JSON.stringify(data),
  });
}

// eslint-disable-next-line no-unused-vars
function httpApiDelete(urlPath, { data, params } = {}) {
  return fetchJson(`${config.apiPath}/${urlPath}`, {
    method: 'DELETE',
    headers: authHeader(token),
    params,
    body: JSON.stringify(data),
  });
}

// add 2359 - add support for posting multipart form data
function httpApiPostFormData(urlPath, { data, params } = {}) {
  return fetchJson(
    `${config.apiPath}/${urlPath}`,
    {
      method: 'POST',
      headers: authHeader(token),
      params,
      body: data,
    },
    'multipart/form-data',
  );
}

function httpApiPutFormData(urlPath, { data, params } = {}) {
  return fetchJson(
    `${config.apiPath}/${urlPath}`,
    {
      method: 'PUT',
      headers: authHeader(token),
      params,
      body: data,
    },
    'multipart/form-data',
  );
}

// add 2359 - add support for custom bootstrap scripts
function getPresignedBootstrapUploadRequests(environmentId, filenames) {
  if (Array.isArray(filenames)) {
    filenames = filenames.join(',');
  }
  return httpApiGet(`api/workspaces/${environmentId}/upload-requests`, { params: { filenames } });
}

// ---------- api calls ---------------

function authenticate(authenticationUrl, username, password, authenticationProviderId) {
  return fetchJson(authenticationUrl, {
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      authenticationProviderId,
    }),
  });
}

// edited by leeas - removed ApiKey calls due to security concern
/* function getApiKeys({ username, ns } = {}) {
  return httpApiGet('api/api-keys', { params: { username, ns } });
}

function createNewApiKey({ username, ns } = {}) {
  return httpApiPost('api/api-keys', { params: { username, ns } });
}

function revokeApiKey(apiKeyId, { username, ns } = {}) {
  return httpApiPut(`api/api-keys/${apiKeyId}/revoke`, { params: { username, ns } });
} */

/*function getDownloadKeys({ username, ns } = {}) {
  return httpApiGet('api/download-keys', { params: { username, ns } });
}

function createNewDownloadKey(name, files, { username, ns } = {}) {
  return httpApiPost('api/download-keys', { params: { username, ns }, data: { name, files } });
}

function revokeDownloadKey(downloadKeyId, { username, ns } = {}) {
  return httpApiPut(`api/download-keys/${downloadKeyId}/revoke`, { params: { username, ns } });
}*/

function getUser() {
  return httpApiGet('api/user');
}

function getUserRoles() {
  return httpApiGet('api/user-roles');
}

// added by leeas
function forceResetPassword(userPoolId, username) {
  return httpApiPost('api/user/forceResetPwd', { data: { userPoolId, username } });
}

function getAwsAccounts() {
  return httpApiGet('api/aws-accounts');
}

function getAwsAccount(accountId) {
  return httpApiGet(`api/aws-accounts/${accountId}`);
}

function addUser(user) {
  const params = {};
  if (user.authenticationProviderId) {
    params.authenticationProviderId = user.authenticationProviderId;
  }
  if (user.identityProviderName) {
    params.identityProviderName = user.identityProviderName;
  }
  return httpApiPost('api/users', { data: user, params });
}

function addUsers(users) {
  return httpApiPost('api/users/bulk', { data: users });
}

function addAwsAccount(awsAccount) {
  return httpApiPost('api/aws-accounts', { data: awsAccount });
}

function createAwsAccount(awsAccount) {
  return httpApiPost('api/aws-accounts/create', { data: awsAccount });
}

function addIndex(index) {
  return httpApiPost('api/indexes', { data: index });
}

function addUserRole(userRole) {
  return httpApiPost('api/user-roles', { data: userRole });
}

function updateUser(user) {
  const params = {};
  if (user.authenticationProviderId) {
    params.authenticationProviderId = user.authenticationProviderId;
  }
  if (user.identityProviderName) {
    params.identityProviderName = user.identityProviderName;
  }
  return httpApiPut(`api/users/${user.username}`, { data: user, params });
}

function updateUserApplication(user) {
  const params = {};
  if (user.authenticationProviderId) {
    params.authenticationProviderId = user.authenticationProviderId;
  }
  if (user.identityProviderName) {
    params.identityProviderName = user.identityProviderName;
  }
  return httpApiPut(`api/users/${user.username}/userself`, { data: user, params });
}

function accedeUserAgreement(user) {
  const params = {};
  if (user.authenticationProviderId) {
      params.authenticationProviderId = user.authenticationProviderId;
  }
  if (user.identityProviderName) {
      params.identityProviderName = user.identityProviderName;
  }
  return httpApiPut(`api/users/${user.username}/acceptUserAgreement`, { data: user, params });
}

function updateUserLogin(user) {
  const params = {};
  if (user.authenticationProviderId) {
      params.authenticationProviderId = user.authenticationProviderId;
  }
  if (user.identityProviderName) {
      params.identityProviderName = user.identityProviderName;
  }
  return httpApiPut(`api/users/${user.username}/updateLoginDate`, { data: user, params });
}
	

function getUsers() {
  return httpApiGet('api/users');
}

async function deleteUser(user) {
  const params = {};
  if (user.authenticationProviderId) {
    params.authenticationProviderId = user.authenticationProviderId;
  }
  if (user.identityProviderName) {
    params.identityProviderName = user.identityProviderName;
  }
  return httpApiDelete(`api/users/${user.username}`, { params });
}

function getAuthenticationProviderPublicConfigs() {
  return httpApiGet('api/authentication/public/provider/configs');
}
function getAuthenticationProviderConfigs() {
  return httpApiGet('api/authentication/provider/configs');
}

function updateAuthenticationProviderConfig(authenticationProvider) {
  return httpApiPut('api/authentication/provider/configs', { data: authenticationProvider });
}

function getStudies(category) {
  return httpApiGet('api/studies', { params: { category } });
}

function getMyStudies() {
  return getStudies('my-studies');
}

function getAccessibleStudies() {
  return getStudies('accessible-studies');
}

function getOrganizationStudies() {
  return getStudies('organization-studies');
}

function getOpenDataStudies() {
  return getStudies('open-data-studies');
}

function getStudy(id) {
  return httpApiGet(`api/studies/${id}`);
}

function isStudyAdmin(studyId, username) {
  return httpApiPost(`api/studies/isStudyAdmin`, { data: { studyId, username } });
}

function isStudyOwner(studyId, username, ns) {
  return httpApiPost(`api/studies/isStudyOwner`, { data: { studyId, username, ns } });
}

function createStudy(body, createResourceLocation = false) {
  const params = {};
  if (createResourceLocation) {
    params.createResourceLocation = createResourceLocation;
  }
  return httpApiPost('api/studies', { data: body, params });
}

function editStudy(studyId, body) {
  return httpApiPut(`api/studies/${studyId}`, { data: body });
}

// add 2359 - add api study type
function updateStudyApiData(study) {
  return httpApiPut(`api/studies/${study.id}`, { data: study });
}

// edit by leeas - added selectedPrefix and changed to POST instead of GET
function listStudyFiles(studyId, selectedPrefix) {
  return httpApiPost(`api/studies/files`, { data: { studyId, selectedPrefix } });
}

function getPresignedStudyUploadRequests(studyId, filenames) {
  if (Array.isArray(filenames)) {
    filenames = filenames.join(',');
  }
  return httpApiGet(`api/studies/${studyId}/upload-requests`, { params: { filenames } });
}

function getUploadCredentials(studyId) {
  return httpApiGet(`api/studies/${studyId}/upload-credentials`);
}

function getStudyPermissions(studyId) {
  return httpApiGet(`api/studies/${studyId}/permissions`);
}

function updateStudyPermissions(studyId, updateRequest) {
  return httpApiPut(`api/studies/${studyId}/permissions`, { data: updateRequest });
}

// start added by 2359
function updateStudyUserPermissions(studyId, updateRequest) {
  return httpApiPut(`api/studies/${studyId}/user-permissions`, { data: updateRequest });
}

function revokeStudyUserPermissions(studyId, updateRequest) {
  return httpApiPut(`api/studies/${studyId}/revoke-permissions`, { data: updateRequest });
}

function requestStudyAccess(studyId, updateRequest, file) {
  const form = new FormData();
  form.append('file', file);
  form.append('details', JSON.stringify(updateRequest));
  return httpApiPutFormData(`api/studies/${studyId}/permissions/request-access`, { data: form });
  // return httpApiPut(`api/studies/${studyId}/permissions/request-access`, { data: updateRequest });
}
// end added by 2359

function searchStudies(criteria) {
  return httpApiPost(`api/search/studies`, { data: { criteria } });
}

// edited by 2359 - change pass in parameter
function grantStudyAccess(studyId, updateRequest) {
  return httpApiPut(`api/studies/${studyId}/permissions/grant-access`, { data: updateRequest });
}

function denyStudyAccess(studyId, username, namespace, adminComments) {
  return httpApiPut(`api/studies/${studyId}/permissions/deny-access`, {
    data: { username, ns: namespace, adminComments },
  });
}

function getStudyAccessRequests() {
  return httpApiGet('api/studies/permissions/access-requests');
}

function deleteStudyData(studyId) {
  return httpApiPut(`api/studies/${studyId}/delete-data`);
}

// start added by leeas
function requestUploadAccess(studyId) {
  return httpApiPut(`api/studies/${studyId}/permissions/upload-access`);
}

function getUploadRequestDirs(unameWithNs, queryStudyAdmin = 'N') {
  return httpApiPost('api/studies/getuploaddirs', { data: { unameWithNs, queryStudyAdmin } });
}

function getUploadDirsByStudy(studyId, unameWithNs = '') {
  return httpApiPost('api/studies/getuploadbystudy', { data: { studyId, unameWithNs } });
}

function createUploadRequest(body) {
  return httpApiPost('api/studies/uploadrequest', { data: body });
}

function updateUploadRequest(body) {
  return httpApiPost('api/studies/updateuploadreq', { data: body });
}

function revokeUploadRequest(body) {
  return httpApiPost('api/studies/revokeuploadreq', { data: body });
}

function changeOwner(body) {
  return httpApiPost('api/studies/changeowner', { data: body });
}
// end added by leeas

async function getWorkflowTemplates() {
  return httpApiGet('api/workflow-templates');
}

async function getWorkflowTemplate(id) {
  return httpApiGet(`api/workflow-templates/${id}`);
}

async function getWorkflowTemplateDrafts() {
  return httpApiGet('api/workflow-templates/drafts');
}

async function createWorkflowTemplateDraft({ isNewTemplate, templateId, templateTitle }) {
  return httpApiPost('api/workflow-templates/drafts', {
    data: {
      isNewTemplate,
      templateId,
      templateTitle,
    },
  });
}

async function updateWorkflowTemplateDraft(draft) {
  return httpApiPut(`api/workflow-templates/drafts/${draft.id}`, { data: draft });
}

async function publishWorkflowTemplateDraft(draft) {
  return httpApiPost('api/workflow-templates/drafts/publish', { data: draft });
}

async function deleteWorkflowTemplateDraft(draft) {
  return httpApiDelete(`api/workflow-templates/drafts/${draft.id}`);
}

async function getStepTemplates() {
  return httpApiGet('api/step-templates');
}

async function getWorkflows() {
  return httpApiGet('api/workflows');
}

async function getWorkflowDrafts() {
  return httpApiGet('api/workflows/drafts');
}

async function createWorkflowDraft({ isNewWorkflow, workflowId, templateId }) {
  return httpApiPost('api/workflows/drafts', {
    data: {
      isNewWorkflow,
      workflowId,
      workflowVer: 0,
      templateId,
      templateVer: 0,
    },
  });
}

async function updateWorkflowDraft(draft) {
  return httpApiPut(`api/workflows/drafts/${draft.id}`, { data: draft });
}

async function publishWorkflowDraft(draft) {
  return httpApiPost('api/workflows/drafts/publish', { data: draft });
}

async function deleteWorkflowDraft(draft) {
  return httpApiDelete(`api/workflows/drafts/${draft.id}`);
}

async function getWorkflow(id) {
  return httpApiGet(`api/workflows/${id}`);
}

async function getWorkflowInstances(id, ver) {
  return httpApiGet(`api/workflows/${id}/v/${ver}/instances`);
}

async function getWorkflowInstance(workflowId, workflowVer, instanceId) {
  return httpApiGet(`api/workflows/${workflowId}/v/${workflowVer}/instances/${instanceId}`);
}

async function triggerWorkflow(workflowId, workflowVer, data) {
  return httpApiPost(`api/workflows/${workflowId}/v/${workflowVer}/trigger`, { data });
}

async function getWorkflowAssignments(id) {
  return httpApiGet(`api/workflows/${id}/assignments`);
}

function getEnvs(envType) {
  return httpApiGet('api/workspaces', { params: { envType } });
}

function getEnvironments() {
  return getEnvs('R');
}

// start added by 2359
function getAmis() {
  return httpApiGet('api/workspaces/amis');
}

function updateAmiPermissions(id, permissions) {
  return httpApiPut(`api/workspaces/amis/${id}/permissions`, { data: { permissions } });
}

function importAmi(ami) {
  return httpApiPost('api/workspaces/amis', { data: ami });
}

function disableAmi(amiId){
  return httpApiPut(`api/workspaces/amis/${amiId}/disable`);
}

function enableAmi(amiId){
  return httpApiPut(`api/workspaces/amis/${amiId}/enable`);
}

function deleteAmi(amiId){
  return httpApiPut(`api/workspaces/amis/${amiId}/delete`);
}

function listAmiPublishRequests() {
  return httpApiGet(`api/workspaces/amis/publish/list`);
}

function publishAmi(amiId){
  return httpApiPut(`api/workspaces/amis/${amiId}/publish`);
}

function approveAmiPublish(amiId, body) {
  return httpApiPut(`api/workspaces/amis/publish/${amiId}/approve`, { data: body });
}

function rejectAmiPublish(amiId, body) {
  return httpApiPut(`api/workspaces/amis/publish/${amiId}/reject`, { data: body });
}

function updateAmi(id, body) {
  return httpApiPut(`api/workspaces/amis/${id}`, { data: body });
}

// end added by 2359

function getEnvironmentCost(id, numberDaysInPast, groupByService = true, groupByUser = false) {
  return httpApiGet(
    `api/costs?env=${id}&numberOfDaysInPast=${numberDaysInPast}&groupByService=${groupByService}&groupByUser=${groupByUser}`,
  );
}

function getS3Cost(proj, numberDaysInPast) {
  return httpApiGet(`api/costs/s3usage?proj=${proj}&numberOfDaysInPast=${numberDaysInPast}`);
}

function getAllProjCostGroupByUser(numberDaysInPast) {
  return httpApiGet(`api/costs?proj=ALL&groupByUser=true&numberOfDaysInPast=${numberDaysInPast}`);
}

function getAllProjCostGroupByEnv(numberDaysInPast) {
  return httpApiGet(`api/costs?proj=ALL&groupByEnv=true&numberOfDaysInPast=${numberDaysInPast}`);
}

function getEnvironment(id) {
  return httpApiGet(`api/workspaces/${id}`);
}

function deleteEnvironment(id) {
  return httpApiDelete(`api/workspaces/${id}`);
}

// start added by 2359
function stopEnvironment(id) {
  return httpApiPut(`api/workspaces/${id}/stop`);
}

function exportEnvironment(id, body) {
  return httpApiPut(`api/workspaces/${id}/export`, {data: body});
}

function startEnvironment(id) {
  return httpApiPut(`api/workspaces/${id}/start`);
}
// end added by 2359

function createEnvironment(body) {
  return httpApiPost('api/workspaces', { data: body });
}

function createSecureEnvironment(body) {
  return httpApiPost('api/workspaces/secure', { data: body });
}

// added by 2359
function createEnvironmentFromAmi(body) {
  return httpApiPost('api/workspaces/ami', { data: body });
}

function createSecureEnvironmentFromAmi(body) {
  return httpApiPost('api/workspaces/ami/secure', { data: body });
}

function updateEnvironment(body) {
  return httpApiPut('api/workspaces', { data: body });
}

// added by leeas
function changeInstanceType(environmentId, newInstanceType) {
  return httpApiPut('api/workspaces/changeinstype', { data: { environmentId, newInstanceType } });
}

function getSettings() {
  return httpApiGet(`api/customSettings`);
}

function updateSetting(id, body) {
  return httpApiPut(`api/customSettings/${id}`, { data: body });
}

function getEnvironmentKeypair(id) {
  return httpApiGet(`api/workspaces/${id}/keypair`);
}

function getEnvironmentPasswordData(id) {
  return httpApiGet(`api/workspaces/${id}/password`);
}

function getEnvironmentBastionPasswordData(id) {
  return httpApiGet(`api/workspaces/${id}/bastion/password`);
}

function getEnvironmentBastionKeypair(id) {
  return httpApiGet(`api/workspaces/${id}/bastion/keypair`);
}

// add chenjqp - add EMR login credentials
function getEnvironmentEmrPasswordData(id) {
  return httpApiGet(`api/workspaces/${id}/emr/password`);
}

function getEnvironmentNotebookUrl(id) {
  return httpApiGet(`api/workspaces/${id}/url`);
}

function getEnvironmentSpotPriceHistory(type) {
  return httpApiGet(`api/workspaces/pricing/${type}`);
}

// start added by 2359
function getEnvironmentOnDemandPrice(type) {
  return httpApiGet(`api/workspaces/onDemand/${type}`);
}

function getCustomEnvironmentConfigurations() {
  return httpApiGet('api/workspaces/customConfig');
}

// added by leeas
function getSagemakerEnvironmentConfigurations() {
  return httpApiGet('api/workspaces/sagemakerConfig');
}	

function refreshCustomEnvironmentConfigurations() {
  return httpApiGet('api/workspaces/customConfig/refresh');
}

// added by leeas
function refreshSagemakerEnvironmentConfigurations() {
  return httpApiGet('api/workspaces/sagemakerConfig/refresh');
}
// end added by 2359

function getIndexes() {
  return httpApiGet('api/indexes');
}

function getIndex(indexId) {
  return httpApiGet(`api/indexes/${indexId}`);
}

function getProjects() {
  return httpApiGet('api/projects');
}

function getProject(id) {
  return httpApiGet(`api/projects/${id}`);
}

function addProject(project) {
  return httpApiPost('api/projects', { data: project });
}

function getAccounts() {
  return httpApiGet('api/accounts');
}

function getAccount(id) {
  return httpApiGet(`api/accounts/${id}`);
}

function removeAccountInfo(id) {
  return httpApiDelete(`api/accounts/${id}`);
}

function listAuditLogs(data) {
  return httpApiPost('api/auditlogs/list', { data });
}

function listWorkflowAuditLogs(workflowId) {
  return httpApiPost(`api/auditlogs/workflow/${workflowId}`);
}

function listExecutionAuditLogs({ workflowId, executionId, limit, nextToken }) {
  return httpApiPost(`api/auditlogs/workflow/${workflowId}/execution/${executionId}`, { limit, nextToken });
}

function listStepAuditLogs(workflowId, executionId, stepId) {
  return httpApiPost(`api/auditlogs/workflow/${workflowId}/execution${executionId}/step/${stepId}`);
}

function putAuditLogComment(workflowId, executionId, comment) {
  const data = {
    comment,
  };
  return httpApiPost(`api/auditlogs/workflow/${workflowId}/execution/${executionId}/comment`, { data });
}

function getTagSet(id) {
  return httpApiGet(`api/tag-sets/${encodeURIComponent(id)}`);
}

function addTag(id, key, values) {
  const data = { key, values };
  return httpApiPost(`api/tag-sets/${encodeURIComponent(id)}`, { data });
}

function editTag(id, key, values, rev) {
  const data = { key, values, rev };
  return httpApiPut(`api/tag-sets/${encodeURIComponent(id)}`, { data });
}

function removeTag(id, key) {
  return httpApiDelete(`api/tag-sets/${encodeURIComponent(id)}/${key}`);
}

function searchTagSetsByTargetType(targetType, criteria) {
  const data = { criteria };
  return httpApiPost(`api/tag-sets/search/by-target-type/${targetType}`, { data });
}

function createSecureOutput(body) {
  return httpApiPost('api/secure-outputs', { data: body });
}

function getSecureOutput(id) {
  return httpApiGet(`api/secure-outputs/${id}`);
}

function getSecureOutputs(category) {
  return httpApiGet('api/secure-outputs', { params: { category } });
}

function getMySecureOutputs() {
  return getSecureOutputs('my-secure-outputs');
}

function getEgressRequestedSecureOutputs() {
  return getSecureOutputs('egress-requests');
}

function getApprovedSecureOutputs() {
  return getSecureOutputs('approved');
}

function getPendingSecureOutputs() {
  return getSecureOutputs('pending');
}

function getRejectedSecureOutputs() {
  return getSecureOutputs('rejected');
}

function getSecureOutputPermissions(secureOutputId) {
  return httpApiGet(`api/secure-outputs/${secureOutputId}/permissions`);
}

function requestSecureOutputEgress(secureOutput) {
  return httpApiPut(`api/secure-outputs/${secureOutput.id}/egress`, { data: secureOutput });
}

function approveSecureOutputEgress(secureOutputId) {
  return httpApiPut(`api/secure-outputs/${secureOutputId}/egress/approve`);
}

function rejectSecureOutputEgress(secureOutputId, adminComments) {
  return httpApiPut(`api/secure-outputs/${secureOutputId}/egress/reject`, { data: adminComments });
}

function deleteSecureOutputData(id) {
  return httpApiPut(`api/secure-outputs/${id}/delete-data`);
}

function secureOutputEgressToStudy(id, secureOutputPrefix, destinationStudyId, destinationStudyPrefix) {
  return httpApiPost(`api/secure-outputs/${id}/egress/tasks`, {
    data: { secureOutputPrefix, destinationStudyId, destinationStudyPrefix },
  });
}

function getSecureOutputEgressTasks(id) {
  return httpApiGet(`api/secure-outputs/${id}/egress/tasks`);
}

// start add 2359 - add support for EMR-hail workspace notebooks, permissions
function getNotebookPermissions(notebookId) {
  return httpApiGet(`api/notebooks/${notebookId}/permissions`);
}

function grantNotebookAccess(notebookId, updateRequest) {
  return httpApiPut(`api/notebooks/${notebookId}/permissions/grant-access`, { data: updateRequest });
}

function denyNotebookAccess(notebookId, username, namespace, adminComments) {
  return httpApiPut(`api/notebooks/${notebookId}/permissions/deny-access`, {
    data: { username, ns: namespace, adminComments },
  });
}

function getNotebookAccessRequests() {
  return httpApiGet('api/notebooks/permissions/access-requests');
}

function updateNotebookPermissions(notebookId, updateRequest) {
  return httpApiPut(`api/notebooks/${notebookId}/permissions`, { data: updateRequest });
}

function updateNotebookUserPermissions(notebookId, updateRequest) {
  return httpApiPut(`api/notebooks/${notebookId}/user-permissions`, { data: updateRequest });
}

function revokeNotebookUserPermissions(notebookId, updateRequest) {
  return httpApiPut(`api/notebooks/${notebookId}/revoke-permissions`, { data: updateRequest });
}

function requestNotebookAccess(notebookId, updateRequest) {
  return httpApiPut(`api/notebooks/${notebookId}/permissions/request-access`, { data: updateRequest });
}

function getNotebooks(category) {
  return httpApiGet('api/notebooks', { params: { category } });
}

function getMyNotebooks() {
  return getNotebooks('my-notebooks');
}

function getOrganizationNotebooks() {
  return getNotebooks('organization-notebooks');
}

function getAccessibleNotebooks() {
  return getNotebooks('accessible-notebooks');
}

function downloadBootstrapNotes(id) {
  return httpApiGet(`api/workspaces/${id}/downloadbootstrap`);
}

function uploadBootstrapfiles(body, id) {
  // console.log('body: ', body);
  return httpApiPostFormData(`api/workspaces/${id}/bootstrap`, { data: body });
}
// end add 2359

// add chenjqp - add support for environment cidr update
function updateEnvironmentSecurity(id, body) {
  return httpApiPut(`api/workspaces/${id}/security`, { data: body });
}

function upgradeStudyToSecure(studyId) {
  return httpApiPut(`api/studies/${studyId}/upgrade`, { data: { studyId } });
}

// start add Reagan
function archiveStudyData(studyId, selectedClass) {
  return httpApiPut(`api/studies/${studyId}/archive-data`, { data: { selectedClass } });
}

function instantRestore(studyId) {
  return httpApiPut(`api/studies/${studyId}/instant-restore`, { data: { studyId } });
}

function restoreStudyData(study) {
  return httpApiPut(`api/studies/${study.id}/restore-study`, { data: study });
}

// WIP
function restoreStudyCollectionsData(study) {
  return httpApiPut(`api/studies/${study.id}/restore-collections`, { data: study });
}

function getRestorationRequests(studyId) {
  return httpApiGet(`api/studies/${studyId}/restorations`);
}
// end add Reagan

function requestPublish(studyId, body) {
  return httpApiPost(`api/studies/${studyId}/publish`, { data: { details: body } });
}

function listRequestsForStudy(studyId) {
  return httpApiGet(`api/studies/${studyId}/publish`);
}

function listStudyPublishRequests() {
  return httpApiGet(`api/publish-requests`);
}

function approveStudyPublish(publishId, body) {
  return httpApiPut(`api/publish-requests/${publishId}/approve`, { data: body });
}

function rejectStudyPublish(publishId, body) {
  return httpApiPut(`api/publish-requests/${publishId}/reject`, { data: body });
}

function listStudyFilesWithTags(studyId, selectedPrefix) {
  return httpApiPost(`api/studies/filesWithCollectionTags`, { data: { studyId, selectedPrefix } });
}

function listStudyFilesWithTagsAndSearch(studyId, queryType, query) {
  return httpApiPost(`api/studies/filesWithCollectionTags`, { data: { studyId, queryType, query } });
}

function updateFolderCollections(studyId, fullPath, collections) {
  return httpApiPut(`api/studies/${studyId}/collectionTags`, { data: { fullPath, collections } });
}

function addCollectiontoBatchFolders(studyId, fullPaths, collection) {
  return httpApiPost(`api/studies/${studyId}/collectionTags/batch`, { data: { fullPaths, collection } });
}

function getStudyCollections(studyId, permission) {
  let url = `api/studies/${studyId}/collections`;
  if (permission) {
    url += `?permission=${permission}`;
  }
  return httpApiGet(url);
}

function createStudyCollection(studyId, body) {
  return httpApiPost(`api/studies/${studyId}/collections`, { data: body });
}

function addToStudyCollection(studyId, collectionId, fullPath, fileType) {
  return httpApiPost(`api/studies/${studyId}/collections/files/${collectionId}`, { data: { fullPath, fileType } });
}

function addBatchToStudyCollection(studyId, files) {
  return httpApiPost(`api/studies/${studyId}/collections/batch/files`, { data: { files } });
}

function removeFromStudyCollection(studyId, collectionId, fullPath, fileType) {
  return httpApiDelete(`api/studies/${studyId}/collections/files/${collectionId}`, { data: { fullPath, fileType } });
}

function getStudyAccessRequestFile(studyId, requestId) {
  return httpApiGet(`api/studies/${studyId}/permissions/request-access/${requestId}/requestFile`);
}

function listSecureOutputFiles(secureOutputId, selectedPrefix) {
  return httpApiPost(`api/secure-outputs/files`, { data: { secureOutputId, selectedPrefix } });
}

function listEnvironmentOutputFiles(environmentId, selectedPrefix) {
  return httpApiPost(`api/workspaces/${environmentId}/files`, { data: { environmentId, selectedPrefix } });
}
// API Functions Insertion Point (do not change this text, it is being used by hygen cli)

// edited by leeas - removed getApiKey, createNewApiKey and revokeApiKey from export
export {
  addIndex,
  configure,
  setIdToken,
  getDecodedIdToken,
  getAuthenticationProviderPublicConfigs,
  getAuthenticationProviderConfigs,
  updateAuthenticationProviderConfig,
  forgetIdToken,
  getUser,
  addUser,
  addUsers,
  removeAccountInfo,
  deleteUser,
  updateUser,
  getUsers,
  getUserRoles,
  addUserRole,
  forceResetPassword,
  getAwsAccounts,
  getAwsAccount,
  authenticate,
  config,
  getStudies,
  getMyStudies,
  getAccessibleStudies,
  getOrganizationStudies,
  getOpenDataStudies,
  getStudy,
  isStudyAdmin,
  isStudyOwner,
  createStudy,
  editStudy,
  updateStudyApiData, // edit 2359 - update api study data
  listStudyFiles,
  getPresignedStudyUploadRequests,
  getUploadCredentials,
  getStudyPermissions,
  updateStudyPermissions,
  updateStudyUserPermissions,
  revokeStudyUserPermissions,
  searchStudies,
  requestStudyAccess,
  grantStudyAccess,
  denyStudyAccess,
  getStudyAccessRequests,
  deleteStudyData,
  archiveStudyData, 
  instantRestore, // start add Reagan
  restoreStudyData, 
  restoreStudyCollectionsData,
  getRestorationRequests, // end add Reagan
  requestUploadAccess,
  getUploadRequestDirs,
  getUploadDirsByStudy,
  createUploadRequest,
  updateUploadRequest,
  revokeUploadRequest,
  changeOwner,
  addAwsAccount,
  createAwsAccount,
  getWorkflowTemplates,
  getWorkflowTemplate,
  getWorkflowTemplateDrafts,
  createWorkflowTemplateDraft,
  updateWorkflowTemplateDraft,
  publishWorkflowTemplateDraft,
  deleteWorkflowTemplateDraft,
  getStepTemplates,
  getWorkflows,
  getWorkflowDrafts,
  createWorkflowDraft,
  updateWorkflowDraft,
  publishWorkflowDraft,
  deleteWorkflowDraft,
  getWorkflowInstances,
  getWorkflow,
  getWorkflowInstance,
  triggerWorkflow,
  getWorkflowAssignments,
  getAmis,
  importAmi,
  updateAmiPermissions,
  getSettings,
  updateSetting,
  getEnvs,
  getEnvironments,
  getEnvironment,
  getEnvironmentCost,
  getS3Cost,	
  getCustomEnvironmentConfigurations,
  getSagemakerEnvironmentConfigurations,	
  refreshCustomEnvironmentConfigurations,
  refreshSagemakerEnvironmentConfigurations,	
  stopEnvironment,
  exportEnvironment,
  startEnvironment,
  deleteEnvironment,
  createEnvironment,
  createSecureEnvironment,
  createEnvironmentFromAmi,
  createSecureEnvironmentFromAmi,
  updateEnvironment,
  changeInstanceType,
  getEnvironmentKeypair,
  getEnvironmentPasswordData,
  getEnvironmentBastionPasswordData,
  getEnvironmentBastionKeypair,
  getEnvironmentEmrPasswordData,
  getEnvironmentNotebookUrl,
  getEnvironmentSpotPriceHistory,
  getEnvironmentOnDemandPrice,
  getAllProjCostGroupByUser,
  getIndexes,
  getIndex,
  getAllProjCostGroupByEnv,
  updateUserApplication,
  accedeUserAgreement,	
  updateUserLogin,
  getProjects,
  getProject,
  addProject,
  getAccounts,
  getAccount,
  listAuditLogs,
  listWorkflowAuditLogs,
  listExecutionAuditLogs,
  listStepAuditLogs,
  putAuditLogComment,
  getTagSet,
  addTag,
  editTag,
  removeTag,
  searchTagSetsByTargetType,
  createSecureOutput,
  getSecureOutput,
  getSecureOutputs,
  getMySecureOutputs,
  getEgressRequestedSecureOutputs,
  getSecureOutputPermissions,
  requestSecureOutputEgress,
  getApprovedSecureOutputs,
  getPendingSecureOutputs,
  getRejectedSecureOutputs,
  approveSecureOutputEgress,
  rejectSecureOutputEgress,
  secureOutputEgressToStudy,
  getSecureOutputEgressTasks,
  deleteSecureOutputData,
  getNotebooks,
  getMyNotebooks,
  getOrganizationNotebooks,
  getAccessibleNotebooks,
  getNotebookPermissions,
  grantNotebookAccess,
  denyNotebookAccess,
  getNotebookAccessRequests,
  updateNotebookPermissions,
  updateNotebookUserPermissions,
  revokeNotebookUserPermissions,
  requestNotebookAccess,
  getPresignedBootstrapUploadRequests,
  downloadBootstrapNotes,
  uploadBootstrapfiles,
  updateEnvironmentSecurity,
  upgradeStudyToSecure,
  requestPublish,
  listRequestsForStudy,
  listStudyPublishRequests,
  approveStudyPublish,
  rejectStudyPublish,
  listStudyFilesWithTags,
  listStudyFilesWithTagsAndSearch,
  updateFolderCollections,
  addCollectiontoBatchFolders,
  getStudyCollections,
  createStudyCollection,
  addToStudyCollection,
  addBatchToStudyCollection,
  removeFromStudyCollection,
  getStudyAccessRequestFile,
  listSecureOutputFiles,
  listEnvironmentOutputFiles,
  enableAmi,
  disableAmi,
  deleteAmi,
  listAmiPublishRequests,
  publishAmi,
  approveAmiPublish,
  rejectAmiPublish,	
  updateAmi  
  // API Export Insertion Point (do not change this text, it is being used by hygen cli)
};
